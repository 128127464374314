// Assignments.js
import React, { useState, useEffect, useCallback } from 'react';
import {
  Accordion,
  AccordionItem,
  Button,
  DataTable,
  Loading,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Tooltip,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from '@carbon/react';

import {
  ArrowRight,
  Information,
  Renew,
  Chat,
  TrashCan,
  DocumentDownload,
  DocumentView,
  PlayOutline,
  Debug,
  Delete,
  CheckmarkOutline,
  ChooseItem,
} from '@carbon/react/icons';

import ReactMarkdown from 'react-markdown';
import JEmoji from 'emoji-toolkit';
import { v4 as uuidv4 } from 'uuid';
import { modelInfo } from './ModelInfo'; 
import { getUpdateAssignmentPrompt } from './AssignmentPrompts.js';
import { ModelSelector } from './ModelSelector';

const ModalContent = ({ modalContent }) => {
  const tryParseJson = input => {
    try {
      console.log('Attempting to parse:', input);
      const parsed = JSON.parse(input);
      console.log('Successfully parsed:', parsed);
      return parsed;
    } catch (error) {
      console.log('Parsing failed, returning original input.');
      return input; // Return the original input if parsing fails
    }
  };

  // Iteratively parse the content until it's no longer a string
  let contentToProcess = modalContent;
  let iterations = 0; // Keep track of the number of parsing iterations
  while (typeof contentToProcess === 'string' && iterations < 3) {
    // Limit iterations to prevent infinite loops
    console.log(
      `Iteration ${iterations + 1}, content before parsing:`,
      contentToProcess
    );
    contentToProcess = tryParseJson(contentToProcess);
    iterations++;
  }

  // After parsing, check if the final content has a 'response' property and is an object
  const isObject =
    typeof contentToProcess === 'object' && contentToProcess !== null;
  const hasResponseProperty = isObject && 'response' in contentToProcess;
  console.log(
    'Final content is an object:',
    isObject,
    ', Has response property:',
    hasResponseProperty
  );

  // The content is considered formatted if there's a 'response' property with string content
  const isFormattedResponse =
    hasResponseProperty && typeof contentToProcess.response === 'string';

  // Function to format the response content
  const formatResponse = response =>
    typeof response === 'string' ? response.replace(/\\n/g, '\n') : response;

  return (
    <div>
      {isFormattedResponse ? (
        // If the response is formatted, display it with line breaks and apply wrapping and margin styles

        <div style={{ margin: '20px' }}>
          <ReactMarkdown>
            {formatResponse(contentToProcess.response)}
          </ReactMarkdown>
        </div>
      ) : (
        // If the response is not formatted, display it as-is but still apply margin for consistency
        <div style={{ margin: '20px' }}>
          <ReactMarkdown>{modalContent}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

const Assignments = ({ token, isActive, user }) => {
  const primaryUrl = 'https://freshfocusai.com';
  //const primaryUrl = 'http://localhost:8000';
  const [currentAssignmentData, setCurrentAssignmentData] = useState({
    steps: [],
  });

  const [periodicTaskRows, setPeriodicTaskRows] = useState([]);
  const [assignmentTaskRows, setAssignmentTaskRows] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); //States for viewing task output
  const [modalContent, setModalContent] = useState('');
  const [currentStep, setCurrentStep] = useState(0); // New state variable for the current step
  const [currentAssignmentOutput, setCurrentAssignmentOutput] = useState([]); //State used for assignment output
  const [currentAssignmentStatus, setCurrentAssignmentStatus] = useState([]); //State used for overall assignment status (single string)
  const [isLoading, setIsLoading] = useState(false);

  const placeholderText =
    'Interact here to create, edit, and run a long running task.';

  const [input, setInput] = useState(''); // Initialize input state
  const [conversationHistory, setConversationHistory] = useState([]);
  const [response, setResponse] = useState(''); // Initialize response state
  const formattedResponse = JEmoji.shortnameToUnicode(response);
  const [conversationId, setConversationId] = useState(null); // Initialize with a new UUID
  const [completedAssignments, setCompletedAssignments] = useState([]);
  const [openItems, setOpenItems] = useState([]); // State to track open accordion items for completedAssignments
  const [selectedTools] = useState(['get_current_assignment']);

  // Local state variables specific to this component
  const [selectedModel, setSelectedModel] = useState(() => {
    return localStorage.getItem('Assignments_selectedModel') || 'gpt-o3-mini';
  });
  
  const [isModelModalOpen, setIsModelModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const technicalSpecsStorageKey = 'Assignments_showTechnicalSpecs';
  const [showTechnicalSpecs, setShowTechnicalSpecs] = useState(() => {
    const saved = localStorage.getItem(technicalSpecsStorageKey);
    // Handle cases where localStorage value is missing or invalid
    return saved !== null ? saved === 'true' : false; // Default to false if no value
  });
  

  // Replace the filteredModels state and useEffect with:
  const filteredModels = React.useMemo(() => {
    const lowerQuery = searchQuery.toLowerCase();
    return Object.keys(modelInfo).filter(modelId => {
      const model = modelInfo[modelId];
      return (
        model.name.toLowerCase().includes(lowerQuery) ||
        model.description.toLowerCase().includes(lowerQuery) ||
        model.strengths.some(s => s.toLowerCase().includes(lowerQuery))
      );
    });
  }, [searchQuery]);

  // Add near the top of your component
  const searchRef = React.useRef(null);
  // Add this ref at the top of your component
  const initialRender = React.useRef(true);

  // Modify your existing useEffect to handle both cases

  useEffect(() => {
    if (isModelModalOpen && searchRef.current) {
      // Only focus if the modal just opened (initialRender flag)
      // or if the search query changed (typing)
      if (!initialRender.current || searchQuery.length > 0) {
        searchRef.current.focus();
      }
    }
  }, [isModelModalOpen, searchQuery]); // Remove showTechnicalSpecs from dependencies


//Save model selection
useEffect(() => {
  localStorage.setItem('Assignments_selectedModel', selectedModel);
}, [selectedModel]);


  const handleDebugViewStep = async stepId => {
    if (!token) {
      console.log('No token, cannot fetch step content');
      return;
    }
    const varName = `${conversationId}_step_${stepId}_conversation_history`;
    const url = `mlapi/premium/task/data/get_binary/assignments/${varName}`;
    console.log(`Getting step ${stepId} url ${url}`);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const textContent = await response.text();

      // Parse and pretty-print the JSON
      const data = JSON.parse(textContent);
      const prettyJson = JSON.stringify(data, null, 2); // Indent with 2 spaces

      // Set the modal content to the pretty-printed JSON
      setModalContent(prettyJson);
      setCurrentStep(stepId);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching step content:', error);
    }
  };

  const handleDeletePeriodicTask = async periodicTaskId => {
    if (!token) {
      console.log('No token, cannot delete');
      return;
    }

    const url = `mlapi/premium/task/state/delete/periodic/${periodicTaskId}`;
    console.log(`Deleting periodic task ${periodicTaskId} url ${url}`);

    try {
      const response = await fetch(url, 
        {
          method: 'DELETE',
          headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await response.text();

      fetchPeriodicTaskData();
    } catch (error) {
      console.error('Error fetching step content:', error);
    }
  };

  const handleDeleteAssignmentTask = async assignmentTaskId => {
    if (!token) {
      console.log('No token, cannot delete');
      return;
    }

    const url = `mlapi/premium/task/data/delete/assignments/${assignmentTaskId}`;
    console.log(`Deleting assignment task ${assignmentTaskId} url ${url}`);

    try {
      const response = await fetch(url, 
        {
          method: 'DELETE',
          headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await response.text();
      fetchAssignmentTaskListData();
    } catch (error) {
      console.error('Error fetching step content:', error);
    }
  };

  const handleToggleEnable = async periodicTaskId => {
    if (!token) {
      console.log('No token, cannot enable or disable');
      return;
    }

    const url = `mlapi/premium/task/state/toggle_enabled/periodic/${periodicTaskId}`;
    console.log(`Toggling enabled for periodic task ${periodicTaskId} url ${url}`);

    try {
      const response = await fetch(url, 
        {
          method: 'PATCH',
          headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await response.text();
      setTimeout(function() {
        fetchCurrentAssignmentData();
      }, 500); // 500 ms delay

      fetchPeriodicTaskData();
    } catch (error) {
      console.error('Error fetching step content:', error);
    }
  };

  const handleMakeAssignmentCurrent = async assignmentTaskId => {
    if (!token) {
      console.log('No token, cannot enable or disable');
      return;
    }

    const url = `mlapi/premium/task/data/save/assignments/current_id`;
    console.log(`Toggling enabled for assignment task ${assignmentTaskId} url ${url}`);

    const payload = {
      "value": `${assignmentTaskId}`,
      "ttl": 0
    };

    try {
      const response = await fetch(url, 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload) 
      });

      await response.text();
      setTimeout(function() {
        fetchCurrentAssignmentData();
      }, 500); // 500 ms delay

      fetchAssignmentTaskListData();
      fetchAssignmentCurrentId();
      fetchCurrentAssignmentData();
    } catch (error) {
      console.error('Error trying to make assignment current:', error);
    }
  };

  const handleViewStep = async stepId => {
    if (!token) {
      console.log('No token, cannot fetch step content');
      return;
    }
    const varName = `${conversationId}_step_${stepId}_conversation_history`;
    const url = `mlapi/premium/task/data/get_binary/assignments/${varName}`;
    console.log(`Getting step ${stepId} url ${url}`);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const textContent = await response.text();
      const data = JSON.parse(textContent);

      // Extract the last message's content
      const lastMessage = data[0]?.messages?.[data[0].messages.length - 1];
      const lastMessageContent = lastMessage?.content || 'No content available';

      // Set the modal content to the last message's content
      setModalContent(lastMessageContent);
      setCurrentStep(stepId);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching step content:', error);
    }
  };

  const handleDownloadStep = async stepId => {
    if (!token) {
      console.log('No token, cannot download step content');
      return;
    }
    const varName = `${conversationId}_step_${stepId}_conversation_history`;
    const url = `mlapi/premium/task/data/get_binary/assignments/${varName}`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Network response was not ok');

      // Get the response text
      const textContent = await response.text();

      // Parse the JSON data
      const data = JSON.parse(textContent);

      // Extract the last message's content
      const lastMessage = data[0]?.messages?.[data[0].messages.length - 1];
      const lastMessageContent = lastMessage?.content || 'No content available';

      // Create a blob from the last message content
      const blob = new Blob([lastMessageContent], { type: 'text/plain' });

      // Create a download link and trigger it
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${stepId}.txt`; // Use .md if the content is in Markdown
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const handleRunStep = async stepId => {
    if (!token) {
      console.log('No token, cannot run task step');
      return;
    }
    console.log('Run task step', stepId);
    const postRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    var assignmentName = conversationId;
    var message = 'Assignment not ready to execute';

    const executeAssignment = await fetch(
      '/mlapi/premium/assignment/v2/execute/' +
        assignmentName +
        '/next_step?step=' +
        stepId,
      postRequestOptions
    );
    if (executeAssignment.ok) {
      const assignmentJson = await executeAssignment.json();
      message = assignmentJson.message;
    } else {
      console.log(
        `Error trying to start assignment: ${executeAssignment.status}`
      );
      message = 'Unable to execute assignment.';
    }

    setResponse('');
    setCurrentAssignmentStatus(message);
  };

  const handleAccordionChange = async conversation_id => {
    if (!token) {
      console.log('No token, cannot fetch conversation details');
      return;
    }
    // Fetch conversation details when an accordion item is expanded
    try {
      const response = await fetch(
        `/mlapi/basic/conversation/${conversation_id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // Assume data includes conversation, status, and output
      setCompletedAssignments(
        completedAssignments.map(conv =>
          conv.conversation_id === conversation_id
            ? { ...conv, content: data }
            : conv
        )
      );
      // Manage open items
      const isOpen = openItems.includes(conversation_id);
      setOpenItems(
        isOpen
          ? openItems.filter(id => id !== conversation_id)
          : [...openItems, conversation_id]
      );
    } catch (error) {
      console.error('Error fetching conversation details:', error);
      // Optionally update UI to show error message
    }
  };

  const fetchCompletedAssignmentsPersistent = async () => {
    if (!token) {
      console.log('No token, cannot fetch completed assignments');
      return;
    }
    try {
      const url = '/mlapi/basic/initial-questions?content_type=ai_assignment';

      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`, // Add token to the request header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCompletedAssignments(
        data
          .map(question => ({
            ...question,
            content: null, // Initialize content as null
          }))
          .reverse()
      ); // Reversing the order after mapping the data
    } catch (error) {
      console.error('Error fetching initial questions:', error);
    }
  };

  const restoreConversation = async (event, conversation_id) => {
    if (!token) {
      console.log('No token, cannot restore conversation');
      return;
    }
    try {
      event.stopPropagation(); // Prevents event from reaching the accordion header
      const response = await fetch(
        `/mlapi/basic/conversation/${conversation_id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Transform the conversation data to match the expected format
      let formattedHistory = [];
      for (let i = 0; i < data.conversation.length; i += 2) {
        // Assuming even indices are user messages and odd indices are system responses
        formattedHistory.push({
          question: data.conversation[i]?.content,
          answer: data.conversation[i + 1]?.content,
        });
      }
      setConversationHistory(formattedHistory);
      await fetchAndProcessConversation(conversation_id);
      setConversationId(conversation_id);
      //Save conversation id as current in redis
      try {
        await saveCurrentConversationId(conversation_id);
      } catch (error) {
        console.error('Failed to save conversation ID:', error);
      }
    } catch (error) {
      console.error('Error fetching conversation details:', error);
    }
  };

  const handleInputChange = e => {
    setInput(e.target.value);
  };

  const clearAssignmentTasks = async () => {
    if (!token) {
      console.log('No token, cannot clear assignment tasks');
      return;
    }
    var assignmentName = 'assignments';
    var assignmentId = conversationId;
    console.log(
      `Clearing assignment tasks for ${assignmentName}/${assignmentId}`
    );
    const deleteRequestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const deleteResponse = await fetch(
      `/mlapi/premium/task/data/${assignmentName}/delete-all-starting-with/${assignmentId}`,
      deleteRequestOptions
    );
    if (!deleteResponse.ok) {
      console.log(
        `Bad response from deleting /mlapi/premium/task/data/${assignmentName}/delete-all-starting-with/${assignmentId} status: ${
          deleteResponse.status
        }`
      );
    } else {
      const deleteData = await deleteResponse.json();
      console.log(deleteData.message); // "Deleted assignment data"
    }

    const deleteAssignmentCurrentResponse = await fetch(
      `/mlapi/premium/task/data/delete/${assignmentName}/current_id`,
      deleteRequestOptions
    );
    if (!deleteAssignmentCurrentResponse.ok) {
      console.log(
        `Bad response from deleting /mlapi/premium/task/data/delete/${assignmentName}/current_id status: ${
          deleteAssignmentCurrentResponse.status
        }`
      );
    } else {
      const deleteData = await deleteAssignmentCurrentResponse.json();
      console.log(deleteData.message);
    }
    var newConversationId = uuidv4();

    //Save conversation id as current in redis
    try {
      await saveCurrentConversationId(newConversationId);
    } catch (error) {
      console.error('Failed to save conversation ID:', error);
    }

    setConversationId(newConversationId);

    setTimeout(function() {
      fetchCurrentAssignmentData();
    }, 500); // 500 ms delay
  };

  const handleClearHistory = async () => {
    console.log('handle clear history');
    setIsLoading(true);
    setConversationHistory([]);
    setResponse('');
    setInput('');
    setCurrentAssignmentStatus('');
    setCurrentAssignmentOutput('');
    setCurrentAssignmentData({ steps: [] });
    await clearAssignmentTasks();
    setIsLoading(false);
  };

  const truncateToTwoSentences = text => {
    if (text.length < 420) {
      const hasMarkup = /[<>{}[\];]/;
      const idx = text.search(hasMarkup);
      if (idx > 160) {
        return text.substr(0, idx) + '...';
      }
      return text; // Make sure to return the original text if no truncation is done
    }

    const sentences = text.match(/[^.!?]+[.!?]+/g);

    return sentences && sentences.length > 2
      ? sentences.slice(0, 2).join(' ') + '...'
      : text;
  };

  async function updateConversation(messages) {
    if (!token) {
      console.log('No token, cannot update conversation');
      return;
    }
    const url = `/mlapi/basic/conversation/${encodeURIComponent(
      conversationId
    )}/update`;

    // Ensure status and output are strings
    const safeStatus =
      typeof currentAssignmentStatus === 'string'
        ? currentAssignmentStatus
        : '';
    const safeOutput =
      typeof currentAssignmentOutput === 'string'
        ? currentAssignmentOutput
        : '';

    // Prepare the payload to include messages and content_type
    const payload = {
      messages: messages,
      content_type: 'ai_assignment',
      model_id: selectedModel,
      status: safeStatus,
      output: safeOutput,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ensure your token is accessible here
        },
        body: JSON.stringify(payload), // Send the payload as JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error during updateConversation API call:', error);
      throw error; // Re-throw the error for further handling if needed
    }
  }

  const trimQuotes = str => str.replace(/^["']|["']$/g, '');

  // New function to fetch and process conversation
  const fetchAndProcessConversation = useCallback(
    async currentId => {
      if (!token) {
        console.log('No token, cannot fetch conversation');
        return;
      }
      console.log('Retrieving conv history for assignment id: ' + currentId);
      try {
        const conversationResponse = await fetch(
          `/mlapi/basic/conversation/${currentId}`,
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`, // Use global token variable directly
            },
          }
        );

        if (!conversationResponse.ok) {
          throw new Error(`HTTP error! Status: ${conversationResponse.status}`);
        }

        const data = await conversationResponse.json();
        let formattedHistory = [];

        // Assuming even indices are user messages and odd indices are system responses
        for (let i = 0; i < data.conversation.length; i += 2) {
          formattedHistory.push({
            question: data.conversation[i]?.content,
            answer: data.conversation[i + 1]?.content,
          });
        }

        setConversationHistory(formattedHistory);
      } catch (err) {
        console.error(
          'Error fetching or processing conversation history:',
          err
        );
      }
    },
    [token]
  );


  const fetchPeriodicTaskData = useCallback(() => {
    if (!token ) {
      console.log('No token, cannot fetch periodic task data');
      return;
    }

    const assignment_data_url = `mlapi/premium/task/state/table_list/periodic`;

    console.log('Fetching periodic table data from: ' + assignment_data_url);

    fetch(assignment_data_url, {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json' is not necessary for GET requests, but it's okay to keep it if required by your API.
      },
    })
      .then(response => {
        if (!response.ok) {
          console.log('Response from current assignment: ' + response.status);
          return []; // Returning empty array
        }
        return response.json(); // Fetch the response as json
      })
      .then(data=> {
        console.log('Received periodic task data: ');
        console.table(data);

        if (data) {
          setPeriodicTaskRows(data);
        }
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch or parsing process
        console.error('Error during fetch or parsing:', error);
      });
  }, [token]); // Dependency array

  const fetchAssignmentTaskListData = useCallback(() => {
    if (!token ) {
      console.log('No token, cannot fetch periodic task data');
      return;
    }

    const assignment_data_url = `mlapi/premium/task/data/table_list/assignments`;

    console.log('Fetching periodic table data from: ' + assignment_data_url);

    fetch(assignment_data_url, {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json' is not necessary for GET requests, but it's okay to keep it if required by your API.
      },
    })
      .then(response => {
        if (!response.ok) {
          console.log('Response from assignment list: ' + response.status);
          return []; // Returning empty array
        }
        return response.json(); // Fetch the response as json
      })
      .then(data=> {
        console.log('Received assignment list task data: ');
        console.table(data);

        if (data) {
          setAssignmentTaskRows(data);
        }
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch or parsing process
        console.error('Error during fetch or parsing:', error);
      });
  }, [token]); // Dependency array

  const fetchCurrentAssignmentData = useCallback(() => {
    if (!token || !conversationId) {
      console.log('No token or conversationId, cannot fetch assignment data');
      return;
    }

    const assignment_data_url = `mlapi/premium/task/data/get/assignments/${conversationId}`;

    console.log('Fetching assignment data from: ' + assignment_data_url);

    fetch(assignment_data_url, {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json' is not necessary for GET requests, but it's okay to keep it if required by your API.
      },
    })
      .then(response => {
        if (!response.ok) {
          console.log('Response from current assignment: ' + response.status);
          return '[]'; // Returning empty string in case of error
        }
        return response.text(); // Fetch the response as plain text
      })
      .then(text => {
        console.log('Received assignment data: ' + text);
        const cleantext = text.replace(/\n/g, ''); // Clean up any newline characters
        console.log('Cleaned assignment data: ' + cleantext);

        let data;
        try {
          data = JSON.parse(cleantext); // First attempt at parsing
          if (typeof data === 'string') {
            data = JSON.parse(data); // Parse again if it’s a stringified JSON object
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          return;
        }

        if (data) {
          console.log('Parsed assignment data', data);
          setCurrentAssignmentData(data);
        }

        // Verify if steps exist and log the structure of the data
        if (data && Array.isArray(data.steps)) {
          console.log('Parsed assignment steps:', data.steps);

          // Extra debugging to ensure no duplicates
          data.steps.forEach((step, index) => {
            console.log(`Step ${index + 1}:`, step);
          });
        } else {
          console.warn('No valid steps found in the assignment data:', data);
        }
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch or parsing process
        console.error('Error during fetch or parsing:', error);
      });
  }, [token, conversationId]); // Dependency array

  //Save conversation ID as current in redis
  const saveCurrentConversationId = useCallback(
    async conversationId => {
      if (!token) {
        console.log('No token, cannot save conversation ID');
        return;
      }
      console.log(`Saving conversation id ${conversationId} with POST`);
      const saveIdRequestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Use global token variable directly
        },
        body: JSON.stringify({
          value: conversationId,
          ttl: 0, // Time to live for the entry, 0 might mean no expiration
        }),
      };
      const saveIdResponse = await fetch(
        '/mlapi/premium/task/data/save/assignments/current_id',
        saveIdRequestOptions
      );
      if (!saveIdResponse.ok) {
        throw new Error(`HTTP error! status: ${saveIdResponse.status}`);
      }
      await saveIdResponse.json(); // Assuming we need to process this json response
    },
    [token]
  ); // Add token as a dependency

  const fetchAssignmentCurrentId = useCallback(async () => {
    if (!token) {
      console.log('No token, cannot fetch current assignment id');
      return;
    }
    console.log('Fetching current assignment id');
    const assignment_data_url =
      'mlapi/premium/task/data/get/assignments/current_id';
    console.log('Fetching assignment id: ' + assignment_data_url);

    try {
      const response = await fetch(assignment_data_url, {
        headers: {
          Authorization: `Bearer ${token}`, // Use global token variable directly
        },
      });

      if (!response.ok) {
        console.debug('Response from get id ' + response.status);
        var newConversationId = uuidv4();
        console.log(`No current_id so created ${newConversationId}`);
        //Save conversation id as current in redis
        try {
          await saveCurrentConversationId(newConversationId);
        } catch (error) {
          console.error('Failed to save conversation ID:', error);
        }
        setConversationId(newConversationId);
        return '';
      }

      const text = await response.text();
      console.log('Received current assignment id: ' + text);

      if (text && text.length > 0) {
        let currentId = trimQuotes(text);
        console.log(`Found  current_id ${currentId}`);
        setConversationId(currentId);
        // Call the new method
        await fetchCurrentAssignmentData();
        await fetchAndProcessConversation(currentId);
      }
    } catch (error) {
      console.error(
        'Error in fetch operation or during response handling:',
        error
      );
      const newConversationId = uuidv4();
      console.log(`Error occurred, created new ID: ${newConversationId}`);
      try {
        await saveCurrentConversationId(newConversationId);
      } catch (saveError) {
        console.error('Failed to save conversation ID:', saveError);
      }
      setConversationId(newConversationId);
    }
  }, [
    token,
    saveCurrentConversationId,
    fetchAndProcessConversation,
    fetchCurrentAssignmentData,
  ]); // Include token in the dependency array if it's part of a context or React state

  useEffect(() => {
    if (!token) {
      console.log('No token, cannot fetch assignment current id');
      return;
    }
    fetchPeriodicTaskData();
    if (!conversationId) {
      fetchAssignmentCurrentId();
    }
  }, [conversationId, fetchAssignmentCurrentId, fetchPeriodicTaskData, token]);

  useEffect(() => {
    if (!token) {
      console.log('No token, cannot fetch assignment data');
      return;
    }
    if (conversationId) {
      fetchCurrentAssignmentData();
      fetchAndProcessConversation(conversationId);
    }
  }, [conversationId, fetchCurrentAssignmentData, fetchAndProcessConversation, token]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!token) {
      setCompletedAssignments([]);
      setOpenItems([]);
      setConversationHistory([]);
      setConversationId(null);
      setInput('');
      setResponse('');
      setCurrentAssignmentStatus('');
      setCurrentAssignmentOutput('');
      setCurrentAssignmentData({ steps: [] });
      setPeriodicTaskRows([]);
      setAssignmentTaskRows([]);
      return;
    }

    const fetchCompletedAssignments = async () => {
      if (!token) {
        console.log('No token, cannot fetch completed assignments');
        return;
      }
      try {
        const url = '/mlapi/basic/initial-questions?content_type=ai_assignment';

        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCompletedAssignments(
          data
            .map(question => ({
              ...question,
              content: null, // Initialize content as null
            }))
            .reverse()
        ); // Reversing the order after mapping the data
      } catch (error) {
        console.error('Error fetching initial questions:', error);
      }
    };

    console.info(`isActive: {isActive}`);
    if (isActive && token) {
      fetchCompletedAssignments();
    } else {
      // Clear completedAssignments if token is null or undefined (i.e., user logged out)
      setCompletedAssignments([]);
      setOpenItems([]);
    }

    var wsHost = primaryUrl;
    var envHost = process.env.REACT_APP_PUBLIC_URL;
    console.log(`envHost=${envHost}`);
    if (envHost && envHost.trim() !== '') {
      wsHost = process.env.REACT_APP_PUBLIC_URL;
      console.log(`Retrieved host ${wsHost}`);
    } else {
      console.log('No process.env found');
    }

    var httpProtocol = wsHost.split('//')[0];
    const wsProtocol = httpProtocol === 'https:' ? 'wss:' : 'ws:';
    wsHost = wsHost.split('//')[1];

    // Construct the WebSocket URL using the current host and the specific path
    var wsUrl = `${wsProtocol}//${wsHost}/mlapi/premium/assignment/ws/assignment_updates/${user}`;
    console.log(`Trying web socket: ${wsUrl}`);
    const ws = new WebSocket(wsUrl);

    ws.onmessage = event => {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        //data = event.data;
      }

      console.log(`Just received event data:`, data); // This will log the object structure
      console.log(
        `Just received event data (stringified): ${JSON.stringify(data)}`
      ); // Stringified version
      fetchCurrentAssignmentData();
      fetchPeriodicTaskData();
      //alert(`Just received event data: ${JSON.stringify(data)}`); // Alerts the stringified object
    };

    return () => {
      ws.close();
    };
  }, [token, user, fetchCurrentAssignmentData, fetchPeriodicTaskData, isActive]); // Dependency array to re-fetch when token changes

  const handleRefreshAssignment = () => {
    fetchAssignmentCurrentId();
  };

  const handleRefreshPeriodicTasks = () => {
    fetchPeriodicTaskData();
  };

  const handleRefreshAssignmentList = () => {
    fetchAssignmentTaskListData();
  };

  const handleExecute = async () => {
    if (!token) {
      console.error('No token, cannot execute.');
      return;
    }
    if (!conversationId) {
      console.error('No conversation ID, cannot submit.');
      return;
    }
    const postRequestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    var assignmentName = conversationId;
    var message = 'Assignment not ready to execute';

    const executeAssignment = await fetch(
      '/mlapi/premium/assignment/v2/execute/' + assignmentName,
      postRequestOptions
    );
    if (executeAssignment.ok) {
      const assignmentJson = await executeAssignment.json();
      message = assignmentJson.message;
    } else {
      console.log(
        `Error trying to start assignment: ${executeAssignment.status}`
      );
      message = 'Unable to execute assignment.';
    }

    setResponse('');
    setCurrentAssignmentStatus(message);
  };

  const deleteConversation = async (event, conversation_id) => {
    if (!token) {
      console.log('No token, cannot delete conversation');
      return;
    }
    event.stopPropagation(); // Prevents event from reaching the accordion header
    if (window.confirm('Would you like to delete this conversation?')) {
      try {
        const response = await fetch(
          `/mlapi/basic/conversation/${conversation_id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Conversation not found.');
          } else if (response.status === 500) {
            throw new Error('Server error occurred.');
          } else {
            throw new Error('An error occurred.');
          }
        }

        // Delay the refresh to ensure backend has processed the deletion
        setTimeout(() => {
          fetchCompletedAssignmentsPersistent();
          setOpenItems([]);
        }, 1000); // 500 milliseconds delay
      } catch (error) {
        console.error(error.message);
        alert(error.message); // Display error to the user
      }
    }
  };

  const refreshCompletedAssignments = () => {
    fetchCompletedAssignmentsPersistent();
    // Reset the content of each conversation
    setCompletedAssignments(
      completedAssignments.map(conv => ({
        ...conv,
        content: null,
      }))
    );
    setOpenItems([]); // Close all accordion items
  };

  const formatContent = content => {
    // Extract status and output from the content object
    const { conversation, status, output } = content;

    // Format the conversation content
    let formattedContent = conversation
      .map((item, index) => {
        var prefix = '';
        if (item.role === 'user') {
          prefix = '** ' + item.content + '** \n\n---\n\n';
        } else {
          prefix = item.content;
        }

        // Add a separator after each answer, but not after the last item
        if (item.role === 'system' && index < conversation.length - 1) {
          prefix += '\n\n---\n\n';
        }

        return prefix;
      })
      .join('\n\n');

    // Append Elasticsearch status and output data
    if (status) {
      formattedContent += `\n\n### Status:\n${status}\n`;
    }
    if (output) {
      formattedContent += `\n\n### Output:\n${output}\n`;
    }

    return formattedContent;
  };

  const handleSubmit = async () => {
    if (!token) {
      console.error('No token, cannot submit.');
      return;
    }
    if (!conversationId) {
      console.error('No conversation ID, cannot submit.');
      return;
    }
    setIsLoading(true); // Start loading
    const now = new Date().toISOString(); // Gets the current date and time in ISO 8601 format
    const prompt = `(Please respond in Markdown format. The date and time is currently: ${now}) My question: ${input}`;

    let systemPrompt = '';
    systemPrompt = getUpdateAssignmentPrompt;

    //Save conversation id as current in redis
    try {
      await saveCurrentConversationId(conversationId);
    } catch (error) {
      console.error('Failed to save conversation ID:', error);
    }

    // Determine the role based on the selected model
    const roleForAIResponse = 'ai';

    let messages = [
      { role: roleForAIResponse, content: systemPrompt }, // Always add the system prompt first
    ];

    if (conversationHistory && conversationHistory.length > 0) {
      // Append the conversation history messages after the system prompt
      const historyMessages = conversationHistory.flatMap(interaction => [
        { role: 'user', content: interaction.question },
        { role: roleForAIResponse, content: interaction.answer },
      ]);
      messages = messages.concat(historyMessages);
    }

    // Add the new user message
    messages.push({ role: 'user', content: prompt });
    const toolsQueryParams = selectedTools
      .map(tool => `tools=${encodeURIComponent(tool)}`)
      .join('&');

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(messages),
    };

    setResponse(`\n  \n** ${input} **\n  \n  `);
    setInput('');
    try {
      const response = await fetch(
        `/mlapi/basic/stream-llm-tools?model=${selectedModel}&${toolsQueryParams}`,
        requestOptions
      );

      if (!response.body) {
        throw new Error('Response body is not readable');
      }

      const reader = response.body.getReader();
      let receivedLength = 0;
      let chunks = [];
      let firstChunkReceived = false;
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        const textChunk = new TextDecoder('utf-8').decode(value, {
          stream: true,
        });
        // Hide loading indicator after first chunk
        if (!firstChunkReceived) {
          setIsLoading(false);
          firstChunkReceived = true;
        }
        setResponse(prevResponse => prevResponse + textChunk);
      }

      // Concatenate chunks into a single string
      let chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      let result = new TextDecoder('utf-8').decode(chunksAll);

      setResponse(`\n  \n**${input}**\n  \n  ${result}`);

      setConversationHistory(prevHistory => [
        ...prevHistory,
        { question: input, answer: result },
      ]);

      const latest_messages = [
        { role: 'user', content: input },
        { role: 'system', content: result },
      ];

      try {
        const response = await updateConversation(latest_messages);
        console.log('Response from update conversation: ', response);
      } catch (error) {
        console.error('Error during API call:', error);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false); // Stop loading in any case
    }
  };

  const allHeaders = [
    { key: 'step_id', header: 'Step' },
    // { key: 'type', header: 'Type' },
    { key: 'description', header: 'Description' },
    { key: 'prompt', header: 'AI Prompt' },
    { key: 'status', header: 'Status' },
    // { key: 'retries', header: 'Retries' },
    { key: 'tools', header: 'Tools' },
    // { key: 'next_steps', header: 'Next Steps' },
    { key: 'actions', header: 'Actions' },
  ];

  const headers = isMobile
    ? [
        { key: 'step_id', header: '#' },
        { key: 'description', header: 'Description' },
        { key: 'actions', header: 'Actions' },
      ]
    : allHeaders;

    const allPeriodicHeaders = [
      { key: 'id', header: 'ID' },
      { key: 'name', header: 'Name' },
      { key: 'description', header: 'Description' },
      { key: 'schedule', header: 'Schedule' },
      { key: 'enabled', header: 'Enabled' },
      { key: 'last_run_time', header: 'Last Run' },
      { key: 'last_run_summary', header: 'Last Run Summary' },
      { key: 'tools', header: 'Tools' },
      { key: 'actions', header: 'Actions' },
    ];
  
    const periodicHeaders = isMobile
      ? [
        { key: 'id', header: 'ID' },
        { key: 'name', header: 'Name' },
        { key: 'schedule', header: 'Schedule' },
        { key: 'enabled', header: 'Enabled' },
        { key: 'actions', header: 'Actions' },
        ]
      : allPeriodicHeaders;

    const periodicRows = (periodicTaskRows || []).map(step => ({
        id: step.id ?? '',
        name: step.name ?? '',
        description: step.description ?? '',
        schedule: step.schedule ?? '',
        enabled: step.enabled?.toString() ?? '',
        last_run_time: step.last_run_time ?? '',
        last_run_summary: step.last_run_summary ?? '',
        tools: step.tools ?? '',
        actions: step.id ?? '',
      }));

    const allAssignmentHeaders = [
        { key: 'id', header: 'ID' },
        { key: 'name', header: 'Name' },
        { key: 'description', header: 'Description' },
        { key: 'actions', header: 'Actions' },
      ];

    const assignmentHeaders = isMobile
      ? [
        { key: 'id', header: 'ID' },
        { key: 'name', header: 'Name' },
        { key: 'actions', header: 'Actions' },
        ]
      : allAssignmentHeaders;

    const assignmentRows = (assignmentTaskRows || []).map(step => ({
        id: step.id ?? '',
        name: step.name ?? '',
        description: step.description ?? '',
      }));

  const rows = (currentAssignmentData?.steps || []).map(step => ({
    id: step.step_id?.toString() ?? '',
    step_id: step.step_id ?? '',
    type: step.type ?? '',
    description: step.description ?? '',
    prompt: step.inputs?.prompt ?? '',
    status: step.status ?? '',
    retries: step.retries ?? '',
    tools: Array.isArray(step.tools) ? step.tools.join(', ') : '',
    actions: step.step_id ?? '',
  }));

  return (
    <>
      <div className="accordion-top-space">
        Delegate long-running assignments here that Fresh Focus AI can break
        down, research, summarize, and complete for you.
      </div>
      <div className="model-selector-container">
      <ModelSelector 
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            isModelModalOpen={isModelModalOpen}
            setIsModelModalOpen={setIsModelModalOpen}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchRef={searchRef}
            filteredModels={filteredModels}
            showTechnicalSpecs={showTechnicalSpecs}
            setShowTechnicalSpecs={setShowTechnicalSpecs}
            techSpecsStorageKey={technicalSpecsStorageKey}
          />
        <Tooltip
          label="You can select a model to help with planning a long running assignment. Review and revise the plan and when ready, hit Execute Assignment"
          align="bottom"
        >
          <Button 
            kind="ghost" 
            size="sm" 
            className="tooltip-button"
            aria-label="Model selection help"
          >
            <Information />
          </Button>
        </Tooltip>
      </div>
      <textarea
        className="llm-request-textarea"
        placeholder={placeholderText}
        value={input}
        onChange={handleInputChange}
        onKeyDown={event => {
          // Check for Enter key without Shift
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent newline
            handleSubmit(); // Call your submit function
          }
        }}
      />
      <Button
        className="llm-submit"
        kind="primary"
        size="small"
        disabled={isLoading}
        onClick={handleSubmit}>
        Discuss <ArrowRight />
      </Button>
      &nbsp;
      <Button
        className="llm-submit"
        kind="secondary"
        size="small"
        onClick={handleClearHistory}>
        Clear
      </Button>
      &nbsp;
      <Button
        className="llm-submit"
        kind="secondary"
        size="small"
        onClick={handleExecute}>
        Execute <ArrowRight />
      </Button>
      <Tooltip
        label="Use Clear to start a new conversation topic.  If Remember is checked, it will still be saved so you can review or resume it in the future."
        align="bottom">
        <button className="sb-tooltip-trigger" type="button" />
      </Tooltip>
      {currentAssignmentOutput && currentAssignmentOutput.length > 0 && (
        <div className="llm-response-area">
          <h4>Output Results</h4>
          <ReactMarkdown>{currentAssignmentOutput}</ReactMarkdown>
        </div>
      )}
      <div className="llm-response-area">
        <ReactMarkdown>{formattedResponse}</ReactMarkdown>
        {isLoading && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '1rem' }}>
                <Loading small description="Generating response" withOverlay={false} />
                <span className="cds--label">Generating response...</span>
              </div>
          )
          } 
      </div>
      {currentAssignmentStatus && currentAssignmentStatus.length > 0 && (
        <div className="llm-response-area">
          <ReactMarkdown>{currentAssignmentStatus}</ReactMarkdown>
        </div>
      )}

<div className="accordion-top-space">
        <>
          <div style={{ padding: '2rem' }}>
            <DataTable rows={rows} headers={headers} isSortable>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
                onInputChange,
              }) => (
                <TableContainer
                  title={
                    currentAssignmentData?.assignment_name &&
                    currentAssignmentData?.current_step
                      ? `Current Assignment Task: ${
                          currentAssignmentData.assignment_name
                        } (step ${currentAssignmentData.current_step})`
                      : 'No assignment defined. You can create one by asking.'
                  }
                  description="List of steps for the assignment task"
                  {...getTableContainerProps()}>
                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch onChange={onInputChange} />
                      <Button
                        hasIconOnly={isMobile}
                        renderIcon={PlayOutline}
                        iconDescription="Run task"
                        onClick={handleExecute}>
                        {!isMobile && 'Run Task'}
                      </Button>
                      <Button
                        hasIconOnly={isMobile}
                        renderIcon={Delete}
                        iconDescription="Delete task"
                        onClick={clearAssignmentTasks}>
                        {!isMobile && 'Delete Task'}
                      </Button>
                      <Button
                        hasIconOnly={isMobile}
                        renderIcon={Renew}
                        iconDescription="Refresh"
                        onClick={handleRefreshAssignment}>
                        {!isMobile && 'Refresh List'}
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map(header => (
                          <TableHeader
                            key={header.key}
                            {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          {row.cells.map(cell => {
                            if (cell.info.header === 'actions') {
                              return (
                                <TableCell key={cell.id}>
                                  <Button
                                    hasIconOnly
                                    renderIcon={DocumentView}
                                    iconDescription="View"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleViewStep(row.id)}
                                  />
                                  <Button
                                    hasIconOnly
                                    renderIcon={Debug}
                                    iconDescription="Debug"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleDebugViewStep(row.id)}
                                  />
                                  <Button
                                    hasIconOnly
                                    renderIcon={DocumentDownload}
                                    iconDescription="Download"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleDownloadStep(row.id)}
                                  />
                                  <Button
                                    hasIconOnly
                                    renderIcon={PlayOutline}
                                    iconDescription="Run"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleRunStep(row.id)}
                                  />
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          </div>

          {isModalOpen && (
            <Modal
              open={isModalOpen}
              modalHeading={`Step ${currentStep} Output`}
              primaryButtonText="Close"
              onRequestClose={() => setIsModalOpen(false)}
              passiveModal={true}>
              <ModalContent modalContent={modalContent} />
            </Modal>
          )}
        </>
      </div>
      <div className="accordion-top-space">
        <>
          <div style={{ padding: '2rem' }}>
            <DataTable rows={assignmentRows} headers={assignmentHeaders} isSortable>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
                onInputChange,
              }) => (
                <TableContainer
                  title={
                    'Assignment Tasks'
                  }
                  description="All AI Long Running Multiple Step Assignment Tasks"
                  {...getTableContainerProps()}>
                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch onChange={onInputChange} />
                      <Button
                        hasIconOnly={isMobile}
                        renderIcon={Renew}
                        iconDescription="Refresh"
                        onClick={handleRefreshAssignmentList}>
                        {!isMobile && 'Refresh List'}
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map(header => (
                          <TableHeader
                            key={header.key}
                            {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          {row.cells.map(cell => {
                            if (cell.info.header === 'actions') {
                              return (
                                <TableCell key={cell.id}>
                                  <Button
                                    hasIconOnly
                                    renderIcon={ChooseItem}
                                    iconDescription="Make assignment current"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleMakeAssignmentCurrent(row.id)}
                                  />
                                  <Button
                                    hasIconOnly
                                    renderIcon={TrashCan}
                                    iconDescription="Delete"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleDeleteAssignmentTask(row.id)}
                                  />
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          </div>

          {isModalOpen && (
            <Modal
              open={isModalOpen}
              modalHeading={`Step ${currentStep} Output`}
              primaryButtonText="Close"
              onRequestClose={() => setIsModalOpen(false)}
              passiveModal={true}>
              <ModalContent modalContent={modalContent} />
            </Modal>
          )}
        </>
      </div>
      <div className="accordion-top-space">
        <>
          <div style={{ padding: '2rem' }}>
            <DataTable rows={periodicRows} headers={periodicHeaders} isSortable>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
                onInputChange,
              }) => (
                <TableContainer
                  title={
                    'Periodic Tasks'
                  }
                  description="AI tasks that run periodically"
                  {...getTableContainerProps()}>
                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch onChange={onInputChange} />
                      <Button
                        hasIconOnly={isMobile}
                        renderIcon={Renew}
                        iconDescription="Refresh"
                        onClick={handleRefreshPeriodicTasks}>
                        {!isMobile && 'Refresh List'}
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map(header => (
                          <TableHeader
                            key={header.key}
                            {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          {row.cells.map(cell => {
                            if (cell.info.header === 'actions') {
                              return (
                                <TableCell key={cell.id}>
                                  <Button
                                    hasIconOnly
                                    renderIcon={CheckmarkOutline}
                                    iconDescription="Enable/Disable"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleToggleEnable(row.id)}
                                  />
                                  <Button
                                    hasIconOnly
                                    renderIcon={TrashCan}
                                    iconDescription="Delete"
                                    kind="ghost"
                                    size="small"
                                    tooltipPosition="top"
                                    tooltipAlignment="center"
                                    onClick={() => handleDeletePeriodicTask(row.id)}
                                  />
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          </div>

          {isModalOpen && (
            <Modal
              open={isModalOpen}
              modalHeading={`Step ${currentStep} Output`}
              primaryButtonText="Close"
              onRequestClose={() => setIsModalOpen(false)}
              passiveModal={true}>
              <ModalContent modalContent={modalContent} />
            </Modal>
          )}
        </>
      </div>
      <div className="accordion-top-space">
        {conversationHistory.length > 0 && (
          <>
            <h4>Assignment Conversation History</h4>
            <Accordion>
              {conversationHistory.map((interaction, index) => (
                <AccordionItem
                  title={truncateToTwoSentences(`Q: ${interaction.question}`)}
                  key={index}>
                  <div className="llm-response-area">
                    <ReactMarkdown>{interaction.answer}</ReactMarkdown>
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        )}
      </div>
      <div className="accordion-top-space">
        <h4>
          Previous Discussions &nbsp; &nbsp;
          {token && (
            <button
              onClick={refreshCompletedAssignments}
              title="Refresh Assignments"
              style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
              <Renew size={18} />
            </button>
          )}
        </h4>
        <Accordion>
          {completedAssignments.map(
            ({ conversation_id, initial_question, content }) => (
              <AccordionItem
                key={conversation_id}
                title={
                  <>
                    <Chat
                      onClick={e => restoreConversation(e, conversation_id)}
                      style={{
                        float: 'right',
                        cursor: 'pointer',
                        marginRight: '25px',
                      }}
                      title="Restore conversation to the AI Assistant tab" // Tooltip text
                    />
                    <TrashCan
                      title="Delete this conversation"
                      onClick={e => deleteConversation(e, conversation_id)}
                      style={{
                        float: 'right',
                        cursor: 'pointer',
                        marginRight: '25px',
                      }}
                    />
                    {truncateToTwoSentences(initial_question)}
                  </>
                }
                open={openItems.includes(conversation_id)}
                onHeadingClick={() => handleAccordionChange(conversation_id)}>
                <div className={`llm-response-area`}>
                  {content ? (
                    <ReactMarkdown>{formatContent(content)}</ReactMarkdown>
                  ) : (
                    <p style={{ paddingTop: '40em', paddingBottom: '40em' }} />
                  )}
                </div>
              </AccordionItem>
            )
          )}
        </Accordion>
      </div>
    </>
  );
};

export default Assignments;
