// AIAssistant.js
import React, { useState, useEffect} from 'react';
import {
  Accordion,
  AccordionItem,
  Button,
  Tooltip,
  MultiSelect,  
  Toggle
} from '@carbon/react';
import { ArrowRight, Information } from '@carbon/react/icons';
import ReactMarkdown from 'react-markdown';
import JEmoji from 'emoji-toolkit';
import { v4 as uuidv4 } from 'uuid';
import { modelInfo } from './ModelInfo';

// Replace the github import with a dark theme

import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import remarkGfm from 'remark-gfm';
// Syntax highlighter (using highlight.js via react-syntax-highlighter)
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ModelSelector } from './ModelSelector';

// Common languages
import jsLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/javascript';
import pythonLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/python';
import bashLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/bash';
import phpLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/php';
import rubyLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/ruby';
import javaLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/java';
import cLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/c';
import cppLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/cpp';
import csharpLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/csharp';
import goLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/go';
import rustLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/rust';
import swiftLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/swift';

// Web-related
import cssLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/css';
import tsLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/typescript';
import xmlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/xml';

// Scripting/config
import perlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/perl';
import sqlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/sql';
import jsonLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import yamlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/yaml';
import iniLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/ini';
//import mdLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/markdown';

// Additional languages
import kotlinLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/kotlin';
import scalaLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/scala';
import rLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/r';
import psLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/powershell';
import groovyLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/groovy';
import shellLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/shell';

// Register languages for highlight.js (add more as needed)
SyntaxHighlighter.registerLanguage('javascript', jsLanguage);
SyntaxHighlighter.registerLanguage('python', pythonLanguage);
SyntaxHighlighter.registerLanguage('bash', bashLanguage);
SyntaxHighlighter.registerLanguage('shell', shellLanguage);
SyntaxHighlighter.registerLanguage('php', phpLanguage);
SyntaxHighlighter.registerLanguage('ruby', rubyLanguage);
SyntaxHighlighter.registerLanguage('java', javaLanguage);
SyntaxHighlighter.registerLanguage('c', cLanguage);
SyntaxHighlighter.registerLanguage('cpp', cppLanguage); // C++
SyntaxHighlighter.registerLanguage('csharp', csharpLanguage); // C#
SyntaxHighlighter.registerLanguage('go', goLanguage);
SyntaxHighlighter.registerLanguage('rust', rustLanguage);
SyntaxHighlighter.registerLanguage('swift', swiftLanguage);

// Web-related
SyntaxHighlighter.registerLanguage('css', cssLanguage);
SyntaxHighlighter.registerLanguage('typescript', tsLanguage);
SyntaxHighlighter.registerLanguage('xml', xmlLanguage);

// Scripting/config
SyntaxHighlighter.registerLanguage('perl', perlLanguage);
SyntaxHighlighter.registerLanguage('sql', sqlLanguage);
SyntaxHighlighter.registerLanguage('json', jsonLanguage);
SyntaxHighlighter.registerLanguage('yaml', yamlLanguage);
SyntaxHighlighter.registerLanguage('ini', iniLanguage);
//SyntaxHighlighter.registerLanguage('markdown', mdLanguage);

// Additional languages
SyntaxHighlighter.registerLanguage('kotlin', kotlinLanguage);
SyntaxHighlighter.registerLanguage('scala', scalaLanguage);
SyntaxHighlighter.registerLanguage('r', rLanguage);
SyntaxHighlighter.registerLanguage('powershell', psLanguage); // PowerShell
SyntaxHighlighter.registerLanguage('groovy', groovyLanguage);

const availableTools = {
  web_search_google: 'Web Search (Google)',
  news_search_google: 'News Search (Google)',
  web_search_tavily: 'Web Search (Tavily)',
  medical_search_pubmed: 'Medical Search (PubMed)',
  reddit_search: 'Reddit Search',
  jobs_search_google: 'Job Search (Google)',
  web_trends_search_google: 'Web Trends Search (Google)',
  scholarly_search_google: 'Scholarly Search (Google)',
  finance_news_search_yahoo: 'Finance News Search (Yahoo)',
  finance_news_search_google: 'Finance News Search (Google)',
  video_search_youtube: 'Video Search (YouTube)',
  programming_search_stackoverflow: 'Programming Search (StackOverflow)',
  encyclopedia_search_wikipedia: 'Encyclopedia Search (Wikipedia)',
  finance_news_search_alphavantage: 'Finance News Search (AlphaVantage)',
  web_search_duckduckgo: 'Web Search (DuckDuckGo)',
  news_search_duckduckgo: 'News Search (DuckDuckGo)',
  goal_search: 'Persistent Search',
};

const AIAssistant = ({
  token,
  placeholderText,
  propInput,
  setInput,
  propConversationId,
  setConversationId,
  propConversationHistory,
  setConversationHistory,
  propResponse,
  setResponse,
}) => {
  // Use the props directly
  const input = propInput;
  const conversationId = propConversationId;
  const conversationHistory = propConversationHistory;
  const response = propResponse;
  const technicalSpecsStorageKey = 'AI_showTechnicalSpecs';

  // Local state variables specific to this component
  const [selectedModel, setSelectedModel] = useState(() => {
    return localStorage.getItem('selectedModel') || 'gpt-o3-mini';
  });
  
  const [rememberConversation, setRememberConversation] = useState(true);
  const [useTools, setUseTools] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTools, setSelectedTools] = useState([
    'web_search_google',
    'web_search_tavily',
    'news_search_google',
    'reddit_search',
  ]);

  const [isModelModalOpen, setIsModelModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [showTechnicalSpecs, setShowTechnicalSpecs] = useState(() => {
    const saved = localStorage.getItem(technicalSpecsStorageKey);
    // Handle cases where localStorage value is missing or invalid
    return saved !== null ? saved === 'true' : false; // Default to false if no value
  });
  

  // Replace the filteredModels state and useEffect with:
  const filteredModels = React.useMemo(() => {
    const lowerQuery = searchQuery.toLowerCase();
    return Object.keys(modelInfo).filter(modelId => {
      const model = modelInfo[modelId];
      return (
        model.name.toLowerCase().includes(lowerQuery) ||
        model.description.toLowerCase().includes(lowerQuery) ||
        model.strengths.some(s => s.toLowerCase().includes(lowerQuery))
      );
    });
  }, [searchQuery]);

  // Add near the top of your component
  const searchRef = React.useRef(null);
  // Add this ref at the top of your component
  const initialRender = React.useRef(true);

  // Modify your existing useEffect to handle both cases

  useEffect(() => {
    if (isModelModalOpen && searchRef.current) {
      // Only focus if the modal just opened (initialRender flag)
      // or if the search query changed (typing)
      if (!initialRender.current || searchQuery.length > 0) {
        searchRef.current.focus();
      }
    }
  }, [isModelModalOpen, searchQuery]); // Remove showTechnicalSpecs from dependencies


//Save model selection
useEffect(() => {
  localStorage.setItem('selectedModel', selectedModel);
}, [selectedModel]);

  const availableToolsArray = Object.entries(availableTools).map(
    ([id, label]) => ({ id, label })
  );

  const handleInputChange = e => {
    setInput(e.target.value);
  };

  const isReasking = () => {
    return conversationHistory.some(q => 
      q.question === input && q.model !== selectedModel
    );
  };
  /*
  const renderCodeBlockLight= ({ inline, className, children, ...props }) => {
    const codeText = String(children).replace(/\n$/, '');
    const languageMatch = /language-(\w+)/.exec(className || '');  
    const language = languageMatch ? languageMatch[1] : null;
    if (inline) {
      // Inline code within a sentence, just render a <code> tag
      return <code className="inline-code">{children}</code>;
    }
    return (
      <div className="code-block">
        <SyntaxHighlighter style={codeStyle} language={language || 'plaintext'} PreTag="div">
          {codeText}
        </SyntaxHighlighter>
        <button className="copy-button" onClick={() => navigator.clipboard.writeText(codeText)}>
          Copy
        </button>
      </div>
    );
  };
  */

  const renderCodeBlock = ({ inline, className, children, ...props }) => {
    const codeText = String(children).replace(/\n$/, '');
    const languageMatch = /language-(\w+)/.exec(className || '');
    const language = languageMatch ? languageMatch[1] : null;
    const effectiveLanguage = language || 'plaintext';

    if (inline) {
      return <code className="inline-code">{children}</code>;
    }

    // For plaintext, return simple pre/code without SyntaxHighlighter
    if (effectiveLanguage === 'plaintext') {
      return (
        <pre className="plaintext-pre">
          <code className="plaintext-code">{codeText}</code>
        </pre>
      );
    }

    // Regular code block with SyntaxHighlighter and decorations
    return (
      <div className="code-block-container">
        <div className="code-block-header">
          <span className="language-label">{effectiveLanguage}</span>
          <button
            className="copy-button"
            onClick={() => navigator.clipboard.writeText(codeText)}>
            📋 Copy
          </button>
        </div>
        <SyntaxHighlighter
          style={atomOneDark}
          language={effectiveLanguage}
          PreTag="div"
          className="syntax-highlighter">
          {codeText}
        </SyntaxHighlighter>
      </div>
    );
  };

  async function updateConversation(conversationId, messages) {
    console.log('Saving conversation history to elasticsearch');
    const url = `/mlapi/basic/conversation/${encodeURIComponent(
      conversationId
    )}/update`;

    // Prepare the payload to include messages and content_type
    const payload = {
      messages: messages,
      content_type: 'ai_conversation',
      model_id: selectedModel,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ensure your token is accessible here
        },
        body: JSON.stringify(payload), // Send the payload as JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error during updateConversation API call:', error);
      throw error; // Re-throw the error for further handling if needed
    }
  }

  const handleClearHistory = () => {
    setConversationHistory([]); // Clears the conversation history
    setResponse(''); // Optionally clear the current response display
    setConversationId(uuidv4()); // Generate a new conversation ID
    setIsLoading(false);
  };

  function truncateToTwoSentences(text) {
    if (text.length < 420) {
      var hasMarkup = /[<>{}[\];]/;
      var idx = text.search(hasMarkup);
      if (idx > 160) {
        return text.substr(0, idx) + '...';
      }
    }

    const sentences = text.match(/[^.!?]+[.!?]+/g);

    return sentences && sentences.length > 2
      ? sentences.slice(0, 2).join(' ') + '...'
      : text;
  }

  const handleToolChange = event => {
    const newSelectedItems = event.selectedItems
      ? event.selectedItems.map(item => item.id)
      : [];

    setSelectedTools(prevSelectedTools => {
      if (
        !Array.isArray(prevSelectedTools) ||
        JSON.stringify(prevSelectedTools) !== JSON.stringify(newSelectedItems)
      ) {
        return newSelectedItems;
      }
      return prevSelectedTools;
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    const now = new Date().toISOString(); // Gets the current date and time in ISO 8601 format
    const prompt = `(Please format your answer in Markdown, using headings, lists, tables. Use fenced code blocks as appropriate if it is a coding question. The date and time is currently: ${now}) ${input}`;
    const roleForAIResponse =
      selectedModel === 'gemini-pro' ? 'model' : 'system';
    const messages = conversationHistory.flatMap(interaction => [
      { role: 'user', content: interaction.question },
      { role: roleForAIResponse, content: interaction.answer },
    ]);
    messages.push({ role: 'user', content: prompt });

    // Build the tools query parameters with repeated `tools=` syntax
    const toolsQueryParams = selectedTools
      .map(tool => `tools=${encodeURIComponent(tool)}`)
      .join('&');

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(messages),
    };

    setResponse(`\n  \n**${input}**\n  \n  `);
    setInput('');

    try {

      const response = await fetch(
        `/mlapi/basic/${
          useTools
            ? `stream-llm-tools?model=${selectedModel}&${toolsQueryParams}`
            : `stream-llm?model=${selectedModel}`
        }`,
        requestOptions
      );
  
      if (!response.body) throw new Error('Response body is not readable');
  
      const reader = response.body.getReader();
      let receivedLength = 0;
      let chunks = [];
      let firstChunkReceived = false;
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        receivedLength += value.length;
  
        const textChunk = new TextDecoder('utf-8').decode(value, {
          stream: true,
        });
        
        // Hide loading indicator after first chunk
        if (!firstChunkReceived) {
          setIsLoading(false);
          firstChunkReceived = true;
        }
        
        setResponse(prevResponse => prevResponse + textChunk);
      }

      let chunksAll = new Uint8Array(receivedLength);
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
      }

      const result = new TextDecoder('utf-8').decode(chunksAll);
      setResponse(`\n  \n**${input}**\n  \n  ${result}`);

      setConversationHistory(prevHistory => [
        ...prevHistory,
        { 
          question: input, 
          answer: result,
          model: selectedModel // Add model info to history
        }
      ]);

      if (rememberConversation) {
        const latest_messages = [
          { role: 'user', content: input },
          { role: 'system', content: result },
        ];
        try {
          const response = await updateConversation(
            conversationId,
            latest_messages
          );
          console.log('Response from update conversation: ', response);
        } catch (error) {
          console.error('Error during API call:', error);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false); // Stop loading in any case
    }
  };

  const formattedResponse = JEmoji.shortnameToUnicode(response);

  return (
    <>
    <div className="toolbar-section">
    <ModelSelector 
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          isModelModalOpen={isModelModalOpen}
          setIsModelModalOpen={setIsModelModalOpen}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchRef={searchRef}
          filteredModels={filteredModels}
          showTechnicalSpecs={showTechnicalSpecs}
          setShowTechnicalSpecs={setShowTechnicalSpecs}
          techSpecsStorageKey={technicalSpecsStorageKey}
        />
      
      <div className="tools-container">
        <div className="tool-toggle-group">
          <Toggle
            id="useToolsToggle"
            labelText="Allow search tools"
            toggled={useTools}
            onToggle={setUseTools}
            className="tools-toggle"
          />
          <Tooltip
            label="When enabled, the AI can use web search tools to enhance responses"
            align="bottom"
          >
            <Button 
              kind="ghost" 
              size="sm" 
              className="tooltip-button"
              aria-label="Search tools help"
            >
              <Information />
            </Button>
          </Tooltip>
        </div>

        {useTools && (
            <MultiSelect
            className="tools-select"
            id="multi-select-tools"
            titleText="Tools" 
            label="Select Search Tools" 
            items={availableToolsArray}
            itemToString={item => (item ? item.label : '')}
            onChange={handleToolChange}
            selectedItems={availableToolsArray.filter(item => 
              selectedTools.includes(item.id)
            )}
            size="sm"
          />
        )}
      </div>
    </div>
      <div className="use-tools-container">
        &nbsp;&nbsp;
      </div>
      <textarea
        className="llm-request-textarea"
        placeholder={placeholderText}
        value={input}
        onChange={handleInputChange}
        onKeyDown={event => {
          // Check for Enter key without Shift
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent newline
            handleSubmit(); // Call your submit function
          }
        }}
      />
      <input
        type="checkbox"
        id="rememberConversation"
        name="rememberConversation"
        checked={rememberConversation}
        onChange={e => setRememberConversation(e.target.checked)}
      />
      <label htmlFor="rememberConversation">Remember</label>
      &nbsp;
      <Tooltip
        label="Select Remember to save the conversation so you may review or continue it in the future. It will also allow Fresh Focus AI to provide more personalized and relevant insights based on this dialogue. You can always review, delete, and continue any saved conversations from the History tab."
        align="bottom">
        <button className="sb-tooltip-trigger" type="button">
          <Information />
        </button>
      </Tooltip>
      <br />
      <div className="action-buttons">
      <Button
        className="llm-submit"
        kind="primary"
        size="small"
        disabled={isLoading}
        onClick={handleSubmit}
        aria-label={isReasking() ? `Re-ask with ${modelInfo[selectedModel]?.name}` : 'Ask question'}
      >
        {isReasking() ? (
          <>Re-ask with {modelInfo[selectedModel]?.name} <ArrowRight /></>
        ) : (
          <>Ask <ArrowRight /></>
        )}
      </Button>
        <Button
          className="llm-submit"
          kind="secondary"
          size="small"
          onClick={handleClearHistory}
        >
          Clear
        </Button>
      </div>
      {isLoading ? (
        <>
        <div className="llm-response-area">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{ code: renderCodeBlock }}>
            {formattedResponse}
          </ReactMarkdown>
        <div className="typing-indicator">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
      </div>
      </div>
      </>
    ) : (
      <div className="llm-response-area">
        {formattedResponse && (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{ code: renderCodeBlock }}>
            {formattedResponse}
          </ReactMarkdown>
        )}
        {conversationHistory.length === 0 && !formattedResponse && (
          <img
            className="landing-page__illo"
            src={`${process.env.PUBLIC_URL}/aiimageprof2.png`}
            alt="DLT Logo"
          />
        )}
      </div>
    )}
      <div className="accordion-top-space">
        {conversationHistory.length > 0 && (
          <>
            <h4>Conversation History</h4>
            <Accordion>
              {conversationHistory.map((interaction, index) => (
                <AccordionItem
                  title={truncateToTwoSentences(`Q: ${interaction.question}`)}
                  key={index}
                >
                  <div className="history-actions">
                    <Button
                      kind="ghost"
                      size="sm"
                      onClick={() => {
                        handleClearHistory();
                        setInput(interaction.question);
                        document.querySelector('.llm-request-textarea').focus();
                      }}
                      aria-label={`Re-ask: ${interaction.question}`}
                    >
                      Re-ask with different model
                    </Button>
                    <Button
                      kind="ghost"
                      size="sm"
                      onClick={() => {
                        setInput(interaction.question);
                        handleSubmit();
                      }}
                      aria-label={`Quick re-ask with current model`}
                    >
                      Re-ask with {modelInfo[selectedModel]?.name}
                    </Button>
                  </div>
                  <div className="llm-response-area">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{ code: renderCodeBlock }}
                    >
                      {interaction.answer}
                    </ReactMarkdown>
                    <div className="model-meta">
                      <small>Answered by: {modelInfo[interaction.model]?.name || 'AI Assistant'}</small>
                    </div>
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </>
        )}
      </div>
    </>
  );
};

export default AIAssistant;
