// ModelSelector.js
import React from 'react';
import { Button, Modal, Search, Toggle, Tooltip } from '@carbon/react';
import { ArrowsVertical, Information } from '@carbon/react/icons';
import { modelInfo } from './ModelInfo';

const ModelCard = ({ modelId, selected, onSelect, showTechnicalSpecs }) => {
  const model = modelInfo[modelId] || { 
    name: modelId, 
    description: 'AI model',
    strengths: [],
    limitations: [],
    parameters: {}
  };

  // Safe parameter access
  const parameters = model.parameters || {};
  const modalities = parameters.modalities || [];

  return (
    <div 
      className={`model-card ${selected ? 'selected' : ''}`} 
      onClick={() => onSelect(modelId)}
      role="button"
      tabIndex={0}
    >
      <div className="model-card-header">
        <h4>{model.name}</h4>
        <Tooltip align="left-bottom" label="Model documentation">
          <Button 
            kind="ghost" 
            size="sm" 
            href={model.link} 
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
            aria-label={`Learn more about ${model.name}`}
          >
            <Information />
          </Button>
        </Tooltip>
      </div>

      <p className="model-description">{model.description}</p>

      <div className="model-attributes">
        <div className="model-strengths">
          <h5>Strengths</h5>
          {model.strengths.map((str, i) => (
            <span key={`strength-${i}`} className="model-tag strength">{str}</span>
          ))}
        </div>
        <div className="model-limitations">
          <h5>Limitations</h5>
          {model.limitations.map((lim, i) => (
            <span key={`limit-${i}`} className="model-tag limitation">{lim}</span>
          ))}
        </div>
      </div>

      {showTechnicalSpecs && (
        <div className="model-parameters">
          <h5 className="parameters-heading">Technical Specifications</h5>
          <div className="parameter-grid">
            {parameters.contextWindow && (
              <div className="parameter-item">
                <span className="parameter-label">Context Window</span>
                <span className="parameter-value">
                  {parameters.contextWindow.toLocaleString()} tokens
                </span>
              </div>
            )}
            {parameters.maxTokens && (
              <div className="parameter-item">
                <span className="parameter-label">Max Output</span>
                <span className="parameter-value">
                  {parameters.maxTokens.toLocaleString()} tokens
                </span>
              </div>
            )}
            {parameters.modelSize && (
              <div className="parameter-item">
                <span className="parameter-label">Model Size</span>
                <span className="parameter-value">
                  {parameters.modelSize}
                </span>
              </div>
            )}
            {parameters.trainingData && (
              <div className="parameter-item">
                <span className="parameter-label">Training Data</span>
                <span className="parameter-value">
                  {parameters.trainingData}
                </span>
              </div>
            )}
            {modalities.length > 0 && (
              <div className="parameter-item">
                <span className="parameter-label">Supported Modalities</span>
                <div className="modality-tags">
                  {modalities.map((modality, i) => (
                    <span key={i} className="modality-tag">
                      {modality}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <div className="parameter-item">
              <span className="parameter-label">Tool Support</span>
              <span className="parameter-value">
                {parameters.toolUse ? '✅ Enabled' : '❌ Disabled'}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ModelSelector = ({
  selectedModel,
  setSelectedModel,
  isModelModalOpen,
  setIsModelModalOpen,
  searchQuery,
  setSearchQuery,
  searchRef,
  filteredModels,
  showTechnicalSpecs,
  setShowTechnicalSpecs,
  techSpecsStorageKey, // New prop to specify the localStorage key
}) => {
  return (
    <div className="model-selector">
      <Button
        kind="ghost"
        size="sm"
        onClick={() => setIsModelModalOpen(true)}
        aria-label="Change AI model"
      >
        <div className="current-model">
          <strong>{modelInfo[selectedModel]?.name}</strong>
          <span className="model-description">
            {modelInfo[selectedModel]?.description}
          </span>
        </div>
        <ArrowsVertical className="model-selector-icon" />
      </Button>

      <Modal
        open={isModelModalOpen}
        modalHeading="Select AI Model"
        passiveModal
        size="lg"
        onRequestClose={() => setIsModelModalOpen(false)}
        className="model-selector-modal"
      >
        <div className="model-modal-controls">
          <Search
            ref={searchRef}
            placeholder="Search models..."
            labelText="Search models"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            className="model-search"
          />
          <Toggle
            id="technicalSpecsToggle"
            labelText="Show technical specs"
            toggled={showTechnicalSpecs}
            onToggle={(newState) => {
              setShowTechnicalSpecs(newState);
              localStorage.setItem(techSpecsStorageKey, newState.toString());
            }}
            className="specs-toggle"
          />
        </div>
        <div className="model-modal-grid">
          {filteredModels.map(modelId => (
            <ModelCard
              key={modelId}
              modelId={modelId}
              selected={selectedModel === modelId}
              onSelect={(id) => {
                setSelectedModel(id);
                setIsModelModalOpen(false);
              }}
              showTechnicalSpecs={showTechnicalSpecs}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export { ModelCard, ModelSelector };
