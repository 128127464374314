import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe("pk_test_51Qin8HDVU3HZxbRKSKWX4TJwFVo4JmMGESkgLAYgN6vVWQcQ2MNzSl1kzrGB9yKyWYzKWSmPNpXfCZVk2UUZYu8200OVbDpUh9");

const StripeWrapper = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeWrapper;