// ConversationHistory.js

import React, { useState, useEffect } from 'react';
import { Accordion, AccordionItem, Loading, Tooltip } from '@carbon/react';
import ReactMarkdown from 'react-markdown';

import {
  Renew,
  Chat,
  TrashCan,
  WatsonMachineLearning,
} from '@carbon/react/icons';

import remarkGfm from 'remark-gfm';
import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

// Common languages
import jsLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/javascript';
import pythonLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/python';
import bashLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/bash';
import phpLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/php';
import rubyLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/ruby';
import javaLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/java';
import cLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/c';
import cppLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/cpp';
import csharpLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/csharp';
import goLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/go';
import rustLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/rust';
import swiftLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/swift';

// Web-related
import cssLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/css';
import tsLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/typescript';
import xmlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/xml';

// Scripting/config
import perlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/perl';
import sqlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/sql';
import jsonLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import yamlLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/yaml';
import iniLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/ini';
//import mdLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/markdown';

// Additional languages
import kotlinLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/kotlin';
import scalaLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/scala';
import rLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/r';
import psLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/powershell';
import groovyLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/groovy';
import shellLanguage from 'react-syntax-highlighter/dist/cjs/languages/hljs/shell';

import Grok2Icon from './../../main/logos/grok2.png'
import AnthropicIcon from './../../main/logos/anthropic.png'
import ChatgptIcon from './../../main/logos/chatgpt.png'
import GeminiIcon from './../../main/logos/gemini.png'
import CohereIcon from './../../main/logos/cohere.jpeg'
import DeepseekIcon from './../../main/logos/deepseek.png'
import LlamaIcon from './../../main/logos/llama.png'

// Register languages for highlight.js (add more as needed)
SyntaxHighlighter.registerLanguage('javascript', jsLanguage);
SyntaxHighlighter.registerLanguage('python', pythonLanguage);
SyntaxHighlighter.registerLanguage('bash', bashLanguage);
SyntaxHighlighter.registerLanguage('shell', shellLanguage);
SyntaxHighlighter.registerLanguage('php', phpLanguage);
SyntaxHighlighter.registerLanguage('ruby', rubyLanguage);
SyntaxHighlighter.registerLanguage('java', javaLanguage);
SyntaxHighlighter.registerLanguage('c', cLanguage);
SyntaxHighlighter.registerLanguage('cpp', cppLanguage); // C++
SyntaxHighlighter.registerLanguage('csharp', csharpLanguage); // C#
SyntaxHighlighter.registerLanguage('go', goLanguage);
SyntaxHighlighter.registerLanguage('rust', rustLanguage);
SyntaxHighlighter.registerLanguage('swift', swiftLanguage);

// Web-related
SyntaxHighlighter.registerLanguage('css', cssLanguage);
SyntaxHighlighter.registerLanguage('typescript', tsLanguage);
SyntaxHighlighter.registerLanguage('xml', xmlLanguage);

// Scripting/config
SyntaxHighlighter.registerLanguage('perl', perlLanguage);
SyntaxHighlighter.registerLanguage('sql', sqlLanguage);
SyntaxHighlighter.registerLanguage('json', jsonLanguage);
SyntaxHighlighter.registerLanguage('yaml', yamlLanguage);
SyntaxHighlighter.registerLanguage('ini', iniLanguage);
//SyntaxHighlighter.registerLanguage('markdown', mdLanguage);

// Additional languages
SyntaxHighlighter.registerLanguage('kotlin', kotlinLanguage);
SyntaxHighlighter.registerLanguage('scala', scalaLanguage);
SyntaxHighlighter.registerLanguage('r', rLanguage);
SyntaxHighlighter.registerLanguage('powershell', psLanguage); // PowerShell
SyntaxHighlighter.registerLanguage('groovy', groovyLanguage);

// Add this version parser function above getModelIndicator
const parseModelVersion = (model) => {
    if (model.startsWith('gpt-')) {
      const version = model.split('-').slice(1).join(' ');
      return version.replace(/mini$/i, '').replace(/(\d)([a-z])/i, '$1 $2').toUpperCase();
    }
    if (model.startsWith('gemini-')) {
      return model.split('-').slice(1).join(' ').replace(/pro/i, 'Pro').replace(/flash/i, 'Flash');
    }
    if (model.startsWith('claude-')) {
      const parts = model.split('-');
      const version = parts[1] + (parts[2] ? ` ${parts[2].charAt(0).toUpperCase() + parts[2].slice(1)}` : '');
      return version.replace(/\d{6,}/, ''); // Remove date suffixes
    }
    if (model.startsWith('deepseek-')) {
      return model.split('-')[1].charAt(0).toUpperCase() + model.split('-')[1].slice(1);
    }
    if (model.startsWith('grok-')) {
      return model.split('-')[1].replace(/latest/i, '');
    }
    if (model.startsWith('cohere_')) {
      return model.split('_').slice(1).join(' ').replace(/command/gi, 'Command').replace(/plus/gi, 'Plus');
    }
    if (model.startsWith('llama-')) {
      return model.split('-')[1];
    }
    return '';
  };
  
// Updated model indicator function with safe defaults
const getModelIndicator = model => {
    // Handle missing/undefined model
    if (!model || typeof model !== 'string') {
      return { 
        Icon: WatsonMachineLearning, 
        base: 'AI', 
        version: '', 
        color: '#64748b' 
      };
    }
  
    const baseConfig = {
        'gpt-': { 
          Icon: ChatgptIcon, // This is now a string path
          base: 'GPT', 
          color: '#22c55e', // Green
          type: 'image' // Add type indicator
        },
        'gemini-': { 
          Icon: GeminiIcon, 
          base: 'Gemini', 
          color: '#8b5cf6', // Purple
          type: 'image'
        },
        'claude-': { 
          Icon: AnthropicIcon, 
          base: 'Claude', 
          color: '#3b82f6', // Blue
          type: 'image' 
        },
        'llama-': { 
          Icon: LlamaIcon, 
          base: 'Llama', 
          color: '#2563eb', // Darker Blue (more contrast)
          type: 'image' 
        },
        'cohere_': { 
          Icon: CohereIcon, 
          base: 'Cohere', 
          color: '#ec4899', // Pink
          type: 'image' 
        },
        'deepseek-': { 
          Icon: DeepseekIcon, 
          base: 'DeepSeek', 
          color: '#9333ea', // Deeper Purple
          type: 'image' 
        },
        'grok-': { 
          Icon: Grok2Icon, 
          base: 'Grok', 
          color: '#f59e0b', // Orange
          type: 'image' 
        }
      };
  
    // Find the matching prefix
    const prefix = Object.keys(baseConfig).find(k => model.startsWith(k));
    
    // Use safe default values
    const defaultConfig = {
      Icon: WatsonMachineLearning,
      base: 'AI',
      color: '#64748b'
    };
    
    const config = prefix ? baseConfig[prefix] : defaultConfig;
    const version = prefix ? parseModelVersion(model) : '';
    
    return { 
      Icon: config.Icon,
      base: config.base,
      version,
      color: config.color
    };
  };

const renderCodeBlock = ({ inline, className, children, ...props }) => {
  const codeText = String(children).replace(/\n$/, '');
  const languageMatch = /language-(\w+)/.exec(className || '');
  const language = languageMatch ? languageMatch[1] : null;
  const effectiveLanguage = language || 'plaintext';

  if (inline) {
    return <code className="inline-code">{children}</code>;
  }

  // For plaintext, return simple pre/code without SyntaxHighlighter
  if (effectiveLanguage === 'plaintext') {
    return (
      <pre className="plaintext-pre">
        <code className="plaintext-code">{codeText}</code>
      </pre>
    );
  }

  // Regular code block with SyntaxHighlighter and decorations
  return (
    <div className="code-block-container">
      <div className="code-block-header">
        <span className="language-label">{effectiveLanguage}</span>
        <button
          className="copy-button"
          onClick={() => navigator.clipboard.writeText(codeText)}>
          📋 Copy
        </button>
      </div>
      <SyntaxHighlighter
        style={atomOneDark}
        language={effectiveLanguage}
        PreTag="div"
        className="syntax-highlighter">
        {codeText}
      </SyntaxHighlighter>
    </div>
  );
};

const ConversationHistory = ({ isActive, token, onRestoreConversation }) => {
  const [conversations, setConversations] = useState([]);
  const [openItems, setOpenItems] = useState([]); // State to track open accordion items
  const [loading, setLoading] = useState(false); // State for loading indicator

  const restoreConversation = async (event, conversation_id) => {
    try {
      event.stopPropagation(); // Prevents event from reaching the accordion header
      const response = await fetch(
        `/mlapi/basic/conversation/${conversation_id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Transform the conversation data to match the expected format
      let formattedHistory = [];
      for (let i = 0; i < data.conversation.length; i += 2) {
        // Assuming even indices are user messages and odd indices are system responses
        formattedHistory.push({
          question: data.conversation[i]?.content,
          answer: data.conversation[i + 1]?.content,
        });
      }

      onRestoreConversation(conversation_id, formattedHistory);
    } catch (error) {
      console.error('Error fetching conversation details:', error);
    }
  };

  const fetchInitialQuestionsPersistent = async () => {
    try {
      setLoading(true); // Start loading
      // Include the content_type parameter in the query string
      const url = '/mlapi/basic/initial-questions?content_type=ai_conversation';

      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`, // Add token to the request header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setConversations(
        data.map(question => ({
          ...question,
          content: null, // Initialize content as null
        }))
      ); // Reversing the order after mapping the data
      setLoading(false); // Stop loading
    } catch (error) {
      console.error('Error fetching initial questions:', error);
      setLoading(false); // Stop loading on error
    }
  };

  useEffect(() => {
    const fetchInitialQuestions = async () => {
      try {
        setLoading(true); // Start loading
        const response = await fetch(
          '/mlapi/basic/initial-questions?content_type=ai_conversation',
          {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`, // Add token to the request header
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setConversations(
          data.map(question => ({
            ...question,
            content: null, // Initialize content as null
          }))
        ); // Reversing the order after mapping the data
        setLoading(false); // Stop loading
      } catch (error) {
        console.error('Error fetching initial questions:', error);
        setLoading(false); // Stop loading on error
      }
    };
    console.info(`isActive: {isActive}`);
    if (isActive && token) {
      fetchInitialQuestions();
    } else {
      // Clear conversations if token is null or undefined (i.e., user logged out)
      setConversations([]);
      setOpenItems([]);
    }
  }, [isActive, token]);

  const handleAccordionChange = async conversation_id => {
    // Fetch conversation details when an accordion item is expanded
    try {
      const response = await fetch(
        `/mlapi/basic/conversation/${conversation_id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`, // Add token to the request header
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setConversations(
        conversations.map(conv =>
          conv.conversation_id === conversation_id
            ? { ...conv, content: data.conversation }
            : conv
        )
      );
      // Manage open items
      const isOpen = openItems.includes(conversation_id);
      setOpenItems(
        isOpen
          ? openItems.filter(id => id !== conversation_id)
          : [...openItems, conversation_id]
      );
    } catch (error) {
      console.error('Error fetching conversation details:', error);
    }
  };

  const refreshConversations = () => {
    fetchInitialQuestionsPersistent();
    // Reset the content of each conversation
    setConversations(
      conversations.map(conv => ({
        ...conv,
        content: null,
      }))
    );
    setOpenItems([]); // Close all accordion items
  };

  const deleteConversation = async (event, conversation_id) => {
    event.stopPropagation(); // Prevents event from reaching the accordion header
    if (window.confirm('Would you like to delete this conversation?')) {
      try {
        const response = await fetch(
          `/mlapi/basic/conversation/${conversation_id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Conversation not found.');
          } else if (response.status === 500) {
            throw new Error('Server error occurred.');
          } else {
            throw new Error('An error occurred.');
          }
        }

        // Delay the refresh to ensure backend has processed the deletion
        setTimeout(() => {
          fetchInitialQuestionsPersistent();
          setOpenItems([]);
        }, 1000); // 1000 milliseconds delay
      } catch (error) {
        console.error(error.message);
        alert(error.message); // Display error to the user
      }
    }
  };

  function truncateToTwoSentences(text) {
    if (text.length < 420) {
      var hasMarkup = /[<>{}[\];]/;
      var idx = text.search(hasMarkup);
      if (idx > 160) {
        return text.substr(0, idx) + '...';
      }
    }

    const sentences = text.match(/[^.!?]+[.!?]+/g);

    return sentences && sentences.length > 2
      ? sentences.slice(0, 2).join(' ') + '...'
      : text;
  }

  const formatContent = content => {
    return content
      .map((item, index) => {
        var formattedContent = '';
        if (item.role === 'user') {
          formattedContent = '**' + item.content + '** \n\n---\n\n';
        } else {
          formattedContent = item.content;
        }

        // Add a separator after each answer, but not after the last item
        if (item.role === 'system' && index < content.length - 1) {
          formattedContent += '\n\n---\n\n';
        }

        return formattedContent;
      })
      .join('\n\n');
  };

  return (
    <div className="accordion-top-space">
      <h4>
        Conversation History &nbsp; &nbsp;
        {token && (
          <button
            onClick={refreshConversations}
            title="Refresh Conversations"
            style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
            <Renew size={18} />
          </button>
        )}
      </h4>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            marginTop: '3em',
          }}>
          <Loading active withOverlay={false} />
        </div>
      ) : (
        <Accordion>
          {conversations.map(
            ({ conversation_id, initial_question, content, model }) => {
              const { Icon, base, version, color } = getModelIndicator(model);
              return (
                <AccordionItem
                  key={conversation_id}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: '8px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          flex: 1,
                          minWidth: 0,
                        }}>
                        <span style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '4px',
                        color: color,
                        flexShrink: 0,
                        }}>
                          <img 
                            src={Icon} 
                            alt={base}
                            style={{ 
                              width: '14px', 
                              height: '14px',
                              borderRadius: '2px',
                              marginRight: '4px'
                            }}
                          />
                        <span style={{
                            fontSize: '0.75rem',
                            fontWeight: 600,
                            whiteSpace: 'nowrap',
                        }}>
                            {base}
                        </span>
                        {version && (
                            <span style={{
                            fontSize: '0.65rem',
                            fontWeight: 400,
                            marginLeft: '2px',
                            opacity: 0.8
                            }}>
                            {version}
                            </span>
                        )}
                        </span>
                        <span
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>
                          {truncateToTwoSentences(initial_question)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          flexShrink: 0,
                        }}>
                        <Tooltip label="Restore and continue conversation on the AI Assistant tab">
                            <Chat 
                            onClick={(e) => restoreConversation(e, conversation_id)} 
                            style={{ float: 'right', cursor: 'pointer', marginRight: '25px'}} 
                            />
                        </Tooltip>
                        <Tooltip label="Delete this conversation">
                            <TrashCan 
                            onClick={(e) => deleteConversation(e, conversation_id)} 
                            style={{ float: 'right', cursor: 'pointer', marginRight: '25px'}} 
                            />
                        </Tooltip>
                      </div>
                    </div>
                  }
                  open={openItems.includes(conversation_id)}
                  onHeadingClick={() => handleAccordionChange(conversation_id)}>
                  <div className={`llm-response-area`}>
                    {content ? (
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{ code: renderCodeBlock }}>
                        {formatContent(content)}
                      </ReactMarkdown>
                    ) : (
                      <p
                        style={{ paddingTop: '40em', paddingBottom: '40em' }}
                      />
                    )}
                  </div>
                </AccordionItem>
              );
            }
          )}
        </Accordion>
      )}
    </div>
  );
};

export default ConversationHistory;
