import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Grid,
  Row,
  Column,
  Accordion,
  AccordionItem,
  Link,
} from '@carbon/react';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import {
  ChartMultitype,
  Integration,
  TaskStar,
  Notification,
  Money,
  Security,
} from '@carbon/icons-react';

const BillingPage = ({ token, user }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    try {
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
      if (stripeError) throw stripeError;

      const response = await fetch('/mlapi/basic/billing/create-subscription', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          payment_method_id: paymentMethod.id,
          amount: 50, // $0.50 USD (test amount)
          currency: 'usd'
        }),
      });

      const result = await response.json();
      if (result.error) throw result.error;

      alert('Subscription created successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message || 'Payment failed');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch('/mlapi/basic/billing/cancel-subscription', {
        method: 'POST',
        headers: { 
          Authorization: `Bearer ${token}`
        },
      });
      const result = await response.json();
      if (result.error) throw result.error;
      alert('Subscription canceled');
    } catch (error) {
      console.error('Error:', error);
      alert(error.message || 'Cancel failed');
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': { color: '#aab7c4' },
      },
      invalid: { color: '#9e2146' },
    },
    hidePostalCode: true
  };

  const membershipFeatures = [
    {
      title: "Multi-Model AI Access",
      icon: ChartMultitype,
      items: [
        "Chat with top AI models: ChatGPT, Claude, Gemini, DeepSeek, Grok & more",
        "Compare responses across different models",
        "Switch models mid-conversation"
      ]
    },
    {
      title: "Advanced Tools & Integrations",
      icon: Integration,
      items: [
        "15+ search engines (Google, Reddit, PubMed, YouTube, etc.)",
        "Cloud storage integration (Google Drive, S3, Evernote)",
        "Full GitHub operations & file management",
        "Email/SMS notifications & file attachments"
      ]
    },
    {
      title: "Smart Task Orchestrator",
      icon: TaskStar,
      items: [
        "Create complex multi-step tasks",
        "Automated data aggregation from multiple sources",
        "Financial analysis & market monitoring",
        "Content generation workflows for research, content, and book writing)"
      ]
    },
    {
      title: "Automation & Insights",
      icon: Notification,
      items: [
        "Schedule periodic tasks (hourly/daily)",
        "AI-curated daily insights based on your interests",
        "Automated research & report generation",
        "Generate text message or email alerts for the exact information you want"
      ]
    }
  ];

  return (
    <div className="billing-page">
    <div className="billing-container">
      <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="membership-features">
            <h2><Money size={20} /> Fresh Focus AI Membership</h2>
              <Grid>
                {membershipFeatures.map((section, index) => (
                  <Column key={index} lg={4} md={4} sm={4}>
                    <div className="feature-section">
                      <div>
                      <section.icon size={24} className="feature-icon" />
                      <h5>{section.title}</h5>
                      <ul>
                        {section.items.map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                      </ul>
                      </div>
                    </div>
                  </Column>
                ))}
              </Grid>
              <Accordion className="value-props">
                <AccordionItem title="Why Join?">
                  <p>Your membership enables:</p>
                  <ul>
                    <li>Access to the latest AI models from numerous providers in one location</li>
                    <li>Built in integration to numerous search engines: Google News Search, Reddit Search, Google Web Search, Tavily Web Search, Wikipedia Search, AlphaVantage Finance News Search, Google Finance News Search, Google Job Search, PubMed Medical Search, DuckDuckGo News Search, StackOverflow programming search, Google Scholar Search, YouTube Video Search, DuckDuckGo Web Search, Google Web Trends Search.</li>
                    <li>Integration with your Evernote, Google Drive, S3, and Github</li>
                    <li>Automatic insights about your interests that you didn't realize you needed</li>
                    <li>You may easily cancel any time from the membership and billing menu</li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </div>
          </Column>

          <Column lg={8} md={8} sm={4}>
            <div className="billing-container">
              <br></br>
              <h4>$20/month</h4>
              <br></br>
              <Form onSubmit={handleSubmit}>
                <FormGroup legendText="Secure Payment">
                  <div className="security-notice">
                    <Security size={16} />
                    <span>128-bit SSL encryption &amp; PCI compliant</span>
                  </div>
                  <br></br>
                  <h4>Credit Card Information</h4>
                  <CardElement options={cardElementOptions} />
                </FormGroup>
                <br></br>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div className="subscription-actions">
                <br></br>
                  <Button type="submit" disabled={loading}>
                    {loading ? 'Processing...' : 'Start Membership'}
                  </Button>
                  <br></br>
                  <p className="cancel-policy">
                  <br></br>
                    Cancel anytime - No long-term commitment
                  </p>
                </div>
              </Form>
              <div className="existing-members">
              <br></br>
                <h3>Manage Subscription</h3>
                <br></br>
                <Button
                  kind="secondary"
                  onClick={handleCancelSubscription}
                  disabled={loading}
                >
                  Cancel Membership
                </Button>
                <br></br>

              </div>
            </div>
          </Column>
      </Grid>
      </div>
    </div>
  );
};

export default BillingPage;
