/* global FB */
import React, { useState, useRef, useEffect } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  TextInput,
  Grid,
  Column,
  Link,
} from '@carbon/react';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { AmazonLoginButton } from 'react-social-login-buttons';
import LogoImage from './../../main/logos/fresh-focus-ai-high-resolution-color-logo.png';
import { useNavigate } from 'react-router-dom';

// Add Amazon SDK initialization
const initializeAmazonSDK = () => {
  return new Promise((resolve) => {
    if (window.amazon) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
    script.onload = () => resolve();
    document.head.appendChild(script);
  });
};


// -----------------------------------------------------------------------------
// Helper function: decodeJwtResponse
// -----------------------------------------------------------------------------
function decodeJwtResponse(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// -----------------------------------------------------------------------------
// Functional component: LoginPage
// -----------------------------------------------------------------------------
function LoginPage(props) {
  // ---------------------------------------------------------------------------
  // State Hooks
  // ---------------------------------------------------------------------------
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // Equivalent of this.state.isClosedBeta = true
  const [isClosedBeta] = useState(true);

  // ---------------------------------------------------------------------------
  // Service/Fetch-related constants (used in login calls)
  // ---------------------------------------------------------------------------
  const serviceheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
  };
  const servicecredtype = 'include';

  // ---------------------------------------------------------------------------
  // Logging system (replicating your log levels)
  // ---------------------------------------------------------------------------
  const LOG_LEVELS = {
    ASSERT: 1,
    ERROR: 2,
    WARN: 3,
    INFO: 4,
    DEBUG: 5,
    TRACE: 6,
  };

  // Create a log object that we can “configure”
  const log = {
    a: () => {},
    e: () => {},
    w: () => {},
    i: () => {},
    d: () => {},
    t: () => {},
    level: LOG_LEVELS.DEBUG, // default log level
  };

  // A small “initializer” function to set up console methods
  (function setLogLevel(level) {
    if (level >= LOG_LEVELS.ASSERT) log.a = console.assert.bind(window.console);
    if (level >= LOG_LEVELS.ERROR) log.e = console.error.bind(window.console);
    if (level >= LOG_LEVELS.WARN) log.w = console.warn.bind(window.console);
    if (level >= LOG_LEVELS.INFO) log.i = console.info.bind(window.console);
    if (level >= LOG_LEVELS.DEBUG) log.d = console.debug.bind(window.console);
    if (level >= LOG_LEVELS.TRACE) log.t = console.log.bind(window.console);
  })(log.level);

  // ---------------------------------------------------------------------------
  // Refs
  // ---------------------------------------------------------------------------
  const amazonLoginRef = useRef(null);

  // ---------------------------------------------------------------------------
  // React Router navigation hook
  // ---------------------------------------------------------------------------
  const navigate = useNavigate();

  // ---------------------------------------------------------------------------
  // Load Facebook SDK on component mount
  // ---------------------------------------------------------------------------
  useEffect(() => {
    // This function will be called when the Facebook SDK is loaded
    window.fbAsyncInit = function () {
      FB.init({
        appId: '1313607190057077', // Replace with your actual Facebook App ID
        cookie: true,
        xfbml: true,
        version: 'v22.0',
      });
    };

    // Load the Facebook SDK script
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  // ---------------------------------------------------------------------------
  // Handlers
  // ---------------------------------------------------------------------------

  // 1) Facebook Login handler
  const handleFacebookLogin = () => {
    if (typeof FB === 'undefined') {
      log.e('Facebook SDK not loaded yet.');
      toast.error('Facebook SDK not loaded. Please try again later.');
      return;
    }

    FB.login(
      response => {
        if (response.authResponse) {
          log.i('Facebook login successful, fetching user details.');
          // Fetch user information from Facebook
          FB.api(
            '/me',
            { fields: 'name,email,first_name,last_name' },
            profileResponse => {
              log.i('Facebook profile response:');
              log.i(profileResponse);
              if (profileResponse && profileResponse.email) {
                const fbEmail = profileResponse.email;
                const fbName = profileResponse.name;
                const fbFirst = profileResponse.first_name;
                const fbLast = profileResponse.last_name;
                const fbToken = response.authResponse.accessToken;

                // Log the Facebook login details
                log.t(
                  `Successful Facebook auth for ${fbEmail} name: ${fbName}`
                );

                // Call your backend endpoint for Facebook login
                const loginURL = '/api/auth/login';
                const heads = {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Cache: 'no-cache',
                };
                const fetchMethod = 'POST';
                const loginPostBody = {
                  id: fbName,
                  email: fbEmail,
                  authType: 'facebook',
                  password: fbToken,
                };

                if (isClosedBeta) {
                  fetch(loginURL, {
                    method: fetchMethod,
                    headers: heads,
                    body: JSON.stringify(loginPostBody),
                    credentials: servicecredtype,
                  })
                    .then(response =>
                      response.json().then(json => ({
                        status: response.status,
                        json,
                      }))
                    )
                    .then(({ status, json }) => {
                      if (status === 200) {
                        log.i('Facebook Login to FF successful');
                        const token = json.data.tokens.access_token;
                        props.setToken(token, fbEmail, fbEmail);
                      } else if (status === 401) {
                        const errmessage = json.message;
                        toast.error(errmessage, {
                          position: 'top-right',
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: 'light',
                        });
                      } else {
                        log.w('Unexpected response status: ' + status);
                      }
                    })
                    .catch(error => {
                      log.w(`Exception in Facebook login: ${error}`);
                    });
                } else {
                  log.t(
                    'Calling login callback for Facebook user (not closed beta)'
                  );
                  props.logincallback(
                    fbEmail,
                    fbEmail,
                    'facebook',
                    fbToken,
                    fbFirst,
                    fbLast,
                    true,
                    '',
                    '',
                    '',
                    ''
                  );
                }
              } else {
                log.w('Facebook profile did not return an email.');
                toast.error('Unable to retrieve email from Facebook.');
              }
            }
          );
        } else {
          log.w('User cancelled Facebook login or did not fully authorize.');
          toast.error('Facebook login cancelled or not fully authorized.');
        }
      },
      { scope: 'public_profile,email' }
    );
  };

 // Initialize Amazon SDK on mount
 useEffect(() => {
  initializeAmazonSDK().then(() => {
    window.amazon.Login.setClientId('amzn1.application-oa2-client.c54d0dbbaa0d4175b80f84475f62f91e');
  });
}, []);

// Amazon Login handler
// Update your Amazon login handler like this:
const handleAmazonLogin = () => {
  window.amazon.Login.authorize({
    scope: 'profile',
    response_type: 'token'
  }, async (amazonResponse) => {  
    if (amazonResponse.error) {
      toast.error(`Amazon login failed: ${amazonResponse.error}`);
      log.e('Amazon login error:', amazonResponse.error);
      return;
    }

    try {
      // Get access token from response
      const accessToken = amazonResponse.access_token;
      
      // Get user profile using access token
      const profileResponse = await fetch('https://api.amazon.com/user/profile', {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      });
      
      if (!profileResponse.ok) throw new Error('Failed to fetch profile');
      const profile = await profileResponse.json();

      // Prepare login payload similar to Facebook
      const loginPayload = {
        id: profile.user_id,
        email: profile.email,
        authType: 'amazon',
        password: accessToken,
      };

      const backendResponse = await fetch('/api/auth/login', {
        method: 'POST',
        headers: serviceheaders,
        body: JSON.stringify(loginPayload),
        credentials: servicecredtype,
      });

      const { status, json } = await backendResponse.json().then(json => ({
        status: backendResponse.status,
        json
      }));

      if (status === 200) {
        props.setToken(json.data.tokens.access_token, profile.email, profile.email);
      } else {
        toast.error(json.message || 'Amazon login failed');
      }
    } catch (error) {
      log.e('Amazon login error:', error);
      toast.error('Failed to authenticate with Amazon');
    }const handleAmazonLogin = () => {
  window.amazon.Login.authorize({
    scope: 'profile',
    response_type: 'token'
  }, async (response) => {
    if (response.error) {
      toast.error(`Amazon login failed: ${response.error}`);
      return;
    }

    try {
      // Get access token from response
      const accessToken = response.access_token;
      
      // Get user profile using access token
      const profileResponse = await fetch('https://api.amazon.com/user/profile', {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      });
      
      if (!profileResponse.ok) throw new Error('Failed to fetch profile');
      const profile = await profileResponse.json();

      // Prepare login payload similar to Facebook
      const loginPayload = {
        id: profile.user_id,
        email: profile.email,
        authType: 'amazon',
        password: accessToken,
      };

      // Call existing backend endpoint
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: serviceheaders,
        body: JSON.stringify(loginPayload),
        credentials: servicecredtype,
      });

      const { status, json } = await response.json().then(json => ({
        status: response.status,
        json
      }));

      if (status === 200) {
        props.setToken(json.data.tokens.access_token, profile.email, profile.email);
      } else {
        toast.error(json.message || 'Amazon login failed');
      }
    } catch (error) {
      log.e('Amazon login error:', error);
      toast.error('Failed to authenticate with Amazon');
    }
  });
};
  });
};


  // 2) Amazon Login success
  const handleOldSocialAmazonLogin = user => {
    log.i('Login: Social Amazon Login');
    log.i(user);
    if (user._profile?.email) {
      const aLogin = user._profile.email;
      const aName = user._profile.name;
      const aFirst = user._profile.firstName;
      const aLast = user._profile.lastName;
      const aToken = user._token.accessToken;

      log.t(
        'Login: Social Amazon successful social auth for name: ' +
          aName +
          ' user: ' +
          aLogin
      );

      // Start check closed beta
      const loginURL = '/api/auth/login';
      const heads = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      };
      const fetchMethod = 'POST';
      const loginPostBody = {
        id: aLogin,
        email: aLogin,
        authType: 'amazon',
        password: aToken,
      };

      if (isClosedBeta) {
        fetch(loginURL, {
          method: fetchMethod,
          headers: heads,
          body: JSON.stringify(loginPostBody),
          credentials: servicecredtype,
        })
          .then(response =>
            response.json().then(json => ({ status: response.status, json }))
          )
          .then(({ status, json }) => {
            log.t('response rc');
            log.t(status);
            if (status === 200) {
              log.t('Amazon Login to FF successful');
              log.t('Login: calling login callback for amazon user');
              log.i('amazonLogin: Found auth successful');
              log.i('response json');
              log.i(json);

              const token = json.data.tokens.access_token;
              log.i('amazonLogin: Calling props.setToken');
              props.setToken(token, aLogin, aLogin);
            } else if (status === 401) {
              log.t('Login: User not permitted');
              const errmessage = json.message;
              toast.error(errmessage, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            } else {
              log.w('Unexpected response status: ' + status);
            }
          })
          .catch(error => {
            log.w(`Exception in Amazon login ${error}`);
            return [];
          });
      } else {
        log.t(
          'Login: calling login callback for amazon user (not closed beta)'
        );
        props.logincallback(
          aLogin,
          aLogin,
          'amazon',
          aToken,
          aFirst,
          aLast,
          true,
          '',
          '',
          '',
          ''
        );
      }
    }
    // End check closed beta
  };

  // 3) Amazon Login failure
  const handleSocialAmazonLoginFailure = err => {
    log.i('Login: Social Login Failure for Amazon');
    console.error(err);
  };

  // 4) Amazon Logout (if you need it)
  const handleSocialLogout = () => {
    log.t('Login: Social logout B');
    if (amazonLoginRef.current && amazonLoginRef.current.amazonLogin) {
      amazonLoginRef.current.amazonLogin.triggerLogout();
    }
  };

  // 5) Native Username/Password login
  const loginWithUsernameAndPassword = async (uname, pw) => {
    const loginURL = '/api/auth/login';
    const loginPostBody = {
      id: uname,
      email: uname,
      authType: 'native',
      password: pw,
    };

    try {
      const response = await fetch(loginURL, {
        method: 'POST',
        headers: serviceheaders,
        body: JSON.stringify(loginPostBody),
        credentials: servicecredtype,
      });

      if (response.ok) {
        const data = await response.json();
        return data.data.tokens.access_token; // Adjust as needed
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      log.w(`Exception in native login ${error}`);
      return null;
    }
  };

  // 6) Form submit for username/password
  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginWithUsernameAndPassword(username, password);

    if (token) {
      props.setToken(token, username, username);
      // e.g. navigate('/dashboard') if desired
    } else {
      toast.error('Login failed. Please check your credentials.', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      });
    }
  };

  // 7) “Create Account” link
  const handleCreateAccountClick = () => {
    navigate('/createaccount');
  };

  // ---------------------------------------------------------------------------
  // Render
  // ---------------------------------------------------------------------------
  return (
    <div className="login-page">
      <Grid fullWidth>
        <Column lg={16} md={8} sm={4} className="login-page__banner">
          <Breadcrumb noTrailingSlash aria-label="Page navigation">
            <BreadcrumbItem>
              <a href="/login">Login</a>
            </BreadcrumbItem>
          </Breadcrumb>
          <h1 className="login-page__heading">Login to Fresh Focus AI</h1>
          <p>
            Don't have an account?{' '}
            <Link onClick={handleCreateAccountClick}>Create an account</Link>
          </p>
        </Column>

        <Column md={10} lg={8} sm={4} className="login-page-content">
          <div className="login-wrapper">
            <form onSubmit={handleSubmit}>
              <TextInput
                id="username"
                labelText=""
                className="login-input"
                placeholder="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
              <TextInput
                id="password"
                labelText=""
                className="login-input"
                placeholder="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Button type="submit">Login</Button>
            </form>
          </div>

          <div className="divider-wrapper">
            <h4>OR Sign in with:</h4>
          </div>

          <div className="social-style-login">
          <div className="button-container">
              {/* --- Google Login --- */}
              <GoogleLogin
                width="258"
                onSuccess={credentialResponse => {
                  log.i('Google Login success.');
                  log.i(credentialResponse);
                  if (credentialResponse.credential) {
                    const credObj = decodeJwtResponse(
                      credentialResponse.credential
                    );
                    // Store Google auth token in state
                    props.setGoogleAuthToken(credentialResponse.credential);
                    log.i('Decoded credential:');
                    log.i(credObj);

                    if (credObj && credObj.email) {
                      const loginURL = '/api/auth/login';
                      const gLogin = credObj.email;
                      const gToken = credentialResponse.credential;
                      const gName = credObj.name;

                      log.i(
                        `Successful google auth for ${gLogin} name: ${gName}`
                      );

                      const heads = {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Cache: 'no-cache',
                      };
                      const fetchMethod = 'POST';
                      const loginPostBody = {
                        id: gName,
                        email: gLogin,
                        authType: 'google',
                        password: gToken,
                      };

                      fetch(loginURL, {
                        method: fetchMethod,
                        headers: heads,
                        body: JSON.stringify(loginPostBody),
                        credentials: servicecredtype,
                      })
                        .then(response => {
                          log.t(
                            '/api/auth/login for google login: response branch'
                          );
                          if (response && response.status === 200) {
                            log.t(
                              `Response from google auth attempt: ${response.status}`
                            );
                          }
                          const statusCode = response.status;
                          return response
                            .json()
                            .then(result => ({ result, statusCode }));
                        })
                        .catch(error => {
                          log.t(`googleLogin: Fetch error ${error}`);
                          return { result: [], statusCode: null };
                        })
                        .then(({ result, statusCode }) => {
                          log.i('googleLogin: login call complete, result:');
                          log.i(result);
                          log.i('googleLogin: status');
                          log.i(statusCode);

                          if (
                            result &&
                            statusCode === 200 &&
                            result.message?.startsWith(
                              'Authentication successful'
                            )
                          ) {
                            log.i('googleLogin: Found auth successful');
                            const token = result.data.tokens.access_token;
                            log.i('googleLogin: Calling props.setToken');
                            props.setToken(token, gLogin, gLogin);
                          } else {
                            log.i('googleLogin: Auth not successful');
                            if (result?.message) {
                              log.i(
                                `googleLogin: Auth message: ${result.message}`
                              );
                            }
                          }
                        });
                    }
                  }
                }}
                onError={() => {
                  console.log('Google Login Failed');
                }}
                useOneTap
                scope="openid profile email https://www.googleapis.com/auth/drive.file"
              />
              {/* Facebook Login */}
              <Button 
                onClick={handleFacebookLogin}
                className="social-button"
                style={{
                  backgroundColor: '#3b5998',
                  color: 'white',
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  minHeight: '38px', 
                  height: '38px'
                }}
              >
                <img 
                  src="/fblogo.png" 
                  alt="Facebook Logo"
                  style={{ marginRight: '8px', width: '20px', height: '20px' }}
                />
                <span>Sign in with Facebook</span>
              </Button>


              {/* Amazon Login */}
              <Button 
                onClick={handleAmazonLogin}
                className="social-button"
                style={{ 
                  backgroundColor: '#FF9900', 
                  border: 'none', 
                  display: 'flex', 
                  alignItems: 'center', 
                  padding: '8px 12px',
                  minHeight: '38px',
                  height: '38px' 
                }}
              >
                <img 
                  src="/amazonlogo.png" 
                  alt="Amazon Logo" 
                  width="20" 
                  height="20" 
                  style={{ marginRight: '8px' }}
                />
                <span style={{ color: 'black', fontWeight: 'bold' }}>Sign in with Amazon</span>
              </Button>
            </div>
          </div>
        </Column>

        <Column
          lg={6}
          className="login-page__logo"
          display={{ base: 'none', lg: 'block' }}>
          <img src={LogoImage} alt="Fresh Focus AI Logo" />
        </Column>
      </Grid>
      <ToastContainer />
    </div>
  );
}

export default LoginPage;
