import React, { useState } from 'react';
import { Button, TextInput, Form, FormGroup } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const CreateAccountPage = () => {
  const navigate = useNavigate();

  // Manage form field states
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Display error or success messages
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Handle submission of form
  const handleCreateAccount = async event => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    // Basic validation checks
    if (!name || !email || !password || !confirmPassword) {
      setErrorMessage('All fields are required.');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      // TODO: Replace with your API call or account creation logic, e.g.:
      // const response = await createUser({ name, email, password });
      // if (response.success) {
      //   setSuccessMessage('Account created successfully!');
      // } else {
      //   setErrorMessage(response.message || 'Error creating account');
      // }

      // For demonstration, we'll just simulate success:
      setSuccessMessage('Account created successfully!');
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again.');
    }
  };

  // Cancel and go back to homepage
  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = () => {
    navigate('/billing');
  };

  return (
    <div className="create-account-page">
      <div className="create-account-container">
        <h1>Create Account</h1>
        <p>
          At Fresh Focus AI, we are committed to helping you create an account
          quickly and securely. Please provide the information below to get
          started.
        </p>

        {/* Use Carbon's Form component for structure */}
        <Form onSubmit={handleCreateAccount}>
          <FormGroup legendText="">
            <TextInput
              id="name"
              labelText="Name"
              placeholder="Enter your full name"
              value={name}
              onChange={e => setName(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
            <TextInput
              id="email"
              labelText="Email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
            <TextInput
              id="password"
              labelText="Password"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
            <TextInput
              id="confirmPassword"
              labelText="Confirm Password"
              type="password"
              placeholder="Re-enter your password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
          </FormGroup>

          {errorMessage && (
            <div style={{ color: 'red', marginBottom: '1rem' }}>
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div style={{ color: 'green', marginBottom: '1rem' }}>
              {successMessage}
            </div>
          )}

          <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
            <Button kind="secondary" onClick={handleCancel} type="button">
              Cancel
            </Button>
            <Button kind="primary" onClick={handleSubmit} type="submit">
              Create Account
            </Button>
          </div>
        </Form>

        <h2 style={{ marginTop: '2rem' }}>Create Account Policy</h2>
        <p>
          Fresh Focus AI collects and stores your conversation history to
          enhance your experience by providing personalized insights and
          recommendations. We do not share your personal data with third parties
          without your consent.
        </p>
        <p>
          For more details, please read our{' '}
          <a
            href="/terms-of-service.html"
            target="_blank"
            rel="noopener noreferrer">
            Terms of Service
          </a>
        </p>
      </div>
    </div>
  );
};

export default CreateAccountPage;
